// src/index.tsx
import React from 'react';
// import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import './style/index.css';
import '@aws-amplify/ui-react/styles.css'; // Import Amplify UI styles
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import { getAppConfig } from './config/get-app-config';

const appConfig = getAppConfig();

// Define callback paths
const AUTH_CALLBACK_PATH = '/login'; // Update this with your callback path if necessary
const AUTH_SCOPE = ['email', 'openid', 'profile'];
const REDIRECT_CALLBACK = `${window.location.origin}${AUTH_CALLBACK_PATH}`;

// Configuring Amplify as per the provided documentation
Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        email: true,
        oauth: {
          domain: appConfig.cognitoDomainName,
          redirectSignIn: [REDIRECT_CALLBACK],
          redirectSignOut: [], // Add appropriate URLs if necessary
          responseType: 'code', // Code flow is generally used for web apps
          scopes: AUTH_SCOPE,
        },
      },
      userPoolClientId: appConfig.cognitoClientID,
      userPoolId: appConfig.cognitoUserPoolID,
      // signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
        given_name: {
          required: true,
        },
        family_name: {
          required: true,
        },
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
});

const domNode = document.getElementById('root') as HTMLElement;
// const root = createRoot(domNode); // Updated to use createRoot from React 18
const root = createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);
