// src\components\deal monitoring\deal-analysis-main.tsx
import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DealTable from './deal-table';
import AnalysesDeal from './analyse-deal';
import { getClientName } from '../../utils/authUtils';  // Import the utility function
import { apiCall } from '../../utils/api'; // Use the centralized apiCall

const MonitorDealing = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('Deal Analysis');
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [clientName, setClientName] = useState<string>(''); 


  // Fetch client name on component mount
  useEffect(() => {
    (async () => {
      const name = await getClientName();
      if (name) setClientName(name);
    })();
  }, []);

  // Fetch company names based on the client name
  const { data, error, isLoading } = useQuery({
    queryKey: ['portfolio-data', clientName],
    queryFn: async () => {
      if (!clientName) return;
      const response = await apiCall(`/portfolio?collectionName=${encodeURIComponent(clientName)}`);
      return response;
    },
    // cacheTime: 0,
    enabled: !!clientName, // Ensure the query runs only when clientName is available
  });
  
  
  

  useEffect(() => {
    if (data) {
      setTableData(data);  // Assuming the API returns the data directly
    }
  }, [data]);

  return (
    <div className='py-2 px-4 font-inter w-full overflow-auto h-screen'>
      <div className='flex w-full items-center'>
        {title !== 'Deal Analysis' && (
          <p
            className='transform rotate-180 font-inter text-3xl cursor-pointer'
            onClick={() => setTitle('Deal Analysis')}
          >
            ➜
          </p>
        )}
        <div className='grow'>
          <PageHeader title={title} />
        </div>
      </div>
      {isLoading ? (
        <p>Loading portfolio data...</p>
      ) : (
        title === 'Deal Analysis' ? (
          <DealTable
            data={tableData}
            setData={setTableData}
            setHeader={setTitle}
            companyName={selectedProject}  // Pass the current selected project
            setCompanyName={setSelectedProject}  // Pass the setter function
            clientName={clientName}
          />
        ) : (
          <AnalysesDeal companyName={selectedProject} clientName={clientName} />  // Pass clientName here
        )
      )}
    </div>
  );
};

export default MonitorDealing;
