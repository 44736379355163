// src/components/legal/legal-main.tsx

import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import LegalTable from './legal-table';
import { getClientName } from '../../utils/authUtils';
import { apiCall } from '../../utils/api';
import ToastMessage from '../ui/toastMessage/toast-message';
import LegalAnalysis from './legal-analysis';
import CompareSFAs from './CompareSFAs'; // Import CompareSFAs component

const LegalMain = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [clientName, setClientName] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [header, setHeader] = useState<string>('Legal Documents'); // Manage header state
  const [dealName, setDealName] = useState<string>(''); // Selected deal name
  const [showCompareSFAs, setShowCompareSFAs] = useState<boolean>(false); // State for CompareSFAs view

  // Fetch client name on component mount
  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const name = await getClientName();
        if (name) {
          setClientName(name);
        } else {
          console.warn('No client name returned');
        }
      } catch (error) {
        console.error('Failed to fetch client name:', error);
      }
    };

    fetchClientName();
  }, []);

  // Fetch company names based on the client name
  const { data: legalDeals, error, isLoading } = useQuery({
    queryKey: ['portfolio-data', clientName],
    queryFn: async () => {
      if (!clientName) return [];
      const response = await apiCall(`/portfolio?collectionName=${encodeURIComponent(clientName)}`);
      return response;
    },
    enabled: !!clientName, // Ensure the query runs only when clientName is available
  });

  useEffect(() => {
    if (legalDeals) {
      setTableData(legalDeals); // Assuming the API returns an array of deal names
    }
  }, [legalDeals]);

  // Handle back navigation
  const handleBack = () => {
    if (showCompareSFAs) {
      setShowCompareSFAs(false);
      setHeader('Legal Documents'); // Reset header if needed
    } else if (dealName) {
      setDealName('');
      setHeader('Legal Documents');
    }
  };

  // Handle opening the Compare SFAs view
  const handleCompareSFAs = () => {
    setShowCompareSFAs(true);
    setHeader('Compare SFAs'); // Update header if needed
  };

  return (
    <div className='py-2 px-4 font-inter w-full overflow-auto h-screen'>
      <div className='flex w-full items-center mb-4'>
        {(dealName || showCompareSFAs) && (
          <p
            className='transform rotate-180 font-inter text-3xl cursor-pointer mr-4'
            onClick={handleBack}
            aria-label="Back to Legal Documents"
          >
            ➜
          </p>
        )}
        <div className='grow'>
          <PageHeader title={header} />
        </div>
      </div>
      {/* Button to open the Compare SFAs view, placed above the table */}
      {!dealName && !showCompareSFAs && (
        <div className="flex justify-end mb-4">
          <button
            onClick={handleCompareSFAs}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition-all duration-200"
          >
            Compare SFAs
          </button>
        </div>
      )}

      {/* Conditional rendering based on the state */}
      {showCompareSFAs ? (
        <CompareSFAs
          clientName={clientName}
          onClose={() => setShowCompareSFAs(false)} // Close Compare SFAs view
          setToast={setShowToast}
          setToastMsg={setToastMsg}
          setToastStatus={setToastStatus}
        />
      ) : dealName ? (
        // If a deal is selected, show the LegalAnalysis component
        <LegalAnalysis
          dealName={dealName}
          clientName={clientName}
          // Removed setHeader and setDealName as they are managed here
        />
      ) : (
        // Otherwise, show the LegalTable component
        <LegalTable
          data={tableData}
          setData={setTableData}
          clientName={clientName}
          showToast={showToast} // Pass as prop
          setToast={setShowToast}
          toastMsg={toastMsg} // Pass as prop
          setToastMsg={setToastMsg}
          toastStatus={toastStatus} // Pass as prop
          setToastStatus={setToastStatus}
          setHeader={setHeader} // Pass setHeader to LegalTable
          setDealName={setDealName} // Pass setDealName to LegalTable
        />
      )}

      {/* Toast Message */}
      {showToast && (
        <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
      )}
    </div>
  );
};

export default LegalMain;
