// src/components/help/HelpMain.tsx
import React from 'react';
import HelpTicketForm from './helpTicketForm';

const HelpMain: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 space-y-12">
      {/* Section 1: PDF to Image Process */}
      <section>
        <h2 className="text-2xl font-semibold mb-4">Print PDFs as Images</h2>
        <p className="mb-4">
          Need assistance with converting and printing your PDFs as images? Follow our detailed guide to seamlessly transform your documents.
        </p>
        <a
          href="/docs/pdf-to-image" // Update this link to your actual documentation or process page
          className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition"
        >
          View PDF to Image Guide
        </a>
      </section>

      {/* Section 2: Help Desk Ticket Form */}
      <section>
        <h2 className="text-2xl font-semibold mb-4">Submit a Help Ticket</h2>
        <HelpTicketForm />
      </section>
    </div>
  );
};

export default HelpMain;
