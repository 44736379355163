// src/utils/PrivateRoute.tsx
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  component: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  if (authStatus !== 'authenticated') {
    return <Navigate to="/login" />;  // Redirects to login if not authenticated
  }

  return component;  // Renders the component if authenticated
};

export default PrivateRoute;
