// src\components\new poject\listOfFile.tsx
import React from 'react';
import file_upload from '../../images/file upload.svg';

interface ListOfFileProps {
  files: File[];
  removeFile: (fileToRemove: File) => void;
}

const ListOfFile: React.FC<ListOfFileProps> = ({ files, removeFile }) => {
  // Debugging: Log received props
  console.log('ListOfFile Props:', { files, removeFile });
  
  return (
    <div className='font-inter w-2/3 my-2 '>
      {/* <h3 className='text-black text-xl font-inter'>File To Add</h3> */}
      <ul className="space-y-2">
        {files.map((file) => (
            <li
            className="flex items-center justify-between gap-3 p-2"
            key={`${file.name}-${file.size}-${file.lastModified}`}
          >
            <div className="flex items-center gap-3">
              <img src={file_upload} alt="File Upload Icon" className="w-6 h-6" />
              <p className='text-gray-700 text-lg'>{file?.name}</p>
              {/* <progress className='w-full bg-black rounded-md' value='0' max='100' /> */}
            </div>
            <button
            onClick={() => removeFile(file)}
            className="text-black text-2xl font-bold focus:outline-none hover:text-gray-700"
            aria-label={`Remove ${file.name}`}
            >
            &times;
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListOfFile;
