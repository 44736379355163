// src/components/ui/button/button.tsx

import React from 'react';
import spinner from '../../../images/Spinner.gif';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className: string;
  label: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ className, label, loading, ...rest }) => {
  return (
    <button className={className} {...rest}>
      {loading ? (
        <img src={spinner} className="w-6 h-6" alt="Loading" />
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
