// src/components/monitoring/monitoring-main.tsx
import React, { useEffect, useState } from 'react';
import PageHeader from '../ui/pageHeader/pageHeader';
import { useQuery } from '@tanstack/react-query';
import MonitoringTable from './monitoring-table'; // Create this component similar to deal-table
import MonitorDeal from './deal-monitor'; // This will be the individual deal monitoring component
import { getClientName } from '../../utils/authUtils';
import InitializeMonitoringModal from './initialize-monitoring-modal';
import { apiCall } from '../../utils/api.ts';
import axios from 'axios';

const MonitoringMain = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('Monitoring');
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reportDate, setReportDate] = useState<string>(''); // Define reportDate state

  // Fetch client name on component mount
  useEffect(() => {
    const fetchClientName = async () => {
      console.log('Fetching client name...');
      try {
        const name = await getClientName();
        console.log('Client name fetched:', name);  // Add a log here
        if (name) {
          setClientName(name);
        } else {
          console.warn('No client name returned');
        }
      } catch (error) {
        console.error('Failed to fetch client name:', error);
      }
    };
  
    fetchClientName();
  }, []);
  
  
// Fetch monitoring data based on the client name
const { data: monitoringData, error: monitoringError, isLoading: isMonitoringLoading } = useQuery({
  queryKey: ['monitoring-data', clientName],
  queryFn: async () => {
    if (!clientName) return;
    console.log(`Fetching monitoring data for client: ${clientName}`); // Add this log
    const response = await apiCall(`/monitoringportfolio?collectionName=${encodeURIComponent(clientName)}`);
    console.log('API Response:', response); // Add this to inspect the API response
    return response;
  },
  // cacheTime: 0,
  enabled: !!clientName // Ensure the query runs only when clientName is available
});
  

  // Use the correct variable in useEffect
  useEffect(() => {
    if (monitoringData) {  // Check if monitoringData is present
      console.log('Monitoring data fetched from API:', monitoringData);
      setTableData(monitoringData);  // Directly set the table data
    }
  }, [monitoringData]);

  // Function to handle opening the modal
    const handleInitializeMonitoring = () => {
      setShowModal(true); // Show the modal when the user clicks the button
  };

  return (
    <div className='py-2 px-4 font-inter w-full overflow-auto h-screen'>
      <div className='flex w-full items-center'>
        {title !== 'Monitoring' && (
          <p
            className='transform rotate-180 font-inter text-3xl cursor-pointer'
            onClick={() => setTitle('Monitoring')}
          >
            ➜
          </p>
        )}
        <div className='grow'>
          <PageHeader title={title} />
        </div>
      </div>
  
      {/* Button to open the modal, placed above the table */}
      {title === 'Monitoring' && (
        <div className="flex justify-end mb-4">
          <button
            onClick={handleInitializeMonitoring}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-md transition-all duration-200"
          >
            Initialize New Monitoring
          </button>
        </div>
      )}
  
      {title === 'Monitoring' ? (
        <MonitoringTable
          data={tableData}
          setData={setTableData}
          setHeader={setTitle}
          companyName={selectedProject}
          setCompanyName={setSelectedProject}
          clientName={clientName}
          setReportDate={setReportDate}
        />
      ) : (
        <MonitorDeal 
          companyName={selectedProject} 
          clientName={clientName}
          reportDate={reportDate}
        />
      )}
  
      {/* Conditionally render the modal */}
      {showModal && (
        <InitializeMonitoringModal
          setShowModal={setShowModal}  // Pass function to close the modal
          clientName={clientName}      // Pass the client name
        />
      )}
    </div>
  );
  
};

export default MonitoringMain;