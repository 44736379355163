import React, { useEffect, useState } from 'react';
import icon_project from '../../../images/icon_newProject.svg';
import icon_settings from '../../../images/icon _settings_.svg';
import icon_analysis from '../../../images/icon _analysis.svg';
import icon_monitoring from '../../../images/icon _monitoring_.svg';
import icon_legal from '../../../images/icon_legal.svg';
import icon_questions from '../../../images/icon _Question Circle_.svg';
import { Link } from 'react-router-dom';
import LogOut from '../../../images/icon _send_message.svg';
import NavBarIconExpand from '../../../images/icon_navbar_expand.svg';
import NavBarIconCollapse from '../../../images/icon_navbar_collapse.svg';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import icon_tableExtractor from '../../../images/icon_table_extractor.svg';

interface NavBarProps {
  isCollapsed: boolean;
  onExpandSidebar: () => void;
  onCollapseSidebar: () => void;
  isSmallScreen: boolean;
}

const NavBar: React.FC<NavBarProps> = ({ isCollapsed, onExpandSidebar, onCollapseSidebar, isSmallScreen }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
  const [userAttributes, setUserAttributes] = useState<{ given_name?: string; family_name?: string } | null>(null);

  const toggleSidebar = () => {
    if (isCollapsed) {
      onExpandSidebar();
    } else {
      onCollapseSidebar();
    }
  };

  async function handleFetchUserAttributes() {
    try {
      const attributes = await fetchUserAttributes();
      const { given_name, family_name } = attributes;
      setUserAttributes({ given_name, family_name });
    } catch (error) {
      console.error('Error fetching user attributes:', error);
    }
  }

  useEffect(() => {
    handleFetchUserAttributes();
  }, []);

  const navBarObject = [
    { img: icon_project, text: 'New Project', link: '/' },
    { img: icon_analysis, text: 'Deal Analysis', link: '/analysis' },
    { img: icon_tableExtractor, text: 'Data Extraction', link: '/data-extraction' },
    { img: icon_legal, text: 'Legal', link: '/legal' },
    { img: icon_monitoring, text: 'Monitoring', link: '/monitoring' },
    { img: icon_settings, text: 'Settings', link: '/settings' },
    { img: icon_questions, text: 'Help', link: '/help' },
  ];

  return (
    <div
      className={`flex-shrink-0 flex flex-col bg-gray_primary h-screen overflow-hidden transition-all duration-300 ${
        isCollapsed ? 'w-16' : 'w-64'
      }`}
      style={{
        position: isSmallScreen && !isCollapsed ? 'absolute' : 'relative',
        zIndex: isSmallScreen && !isCollapsed ? 1000 : 'auto',
      }}
    >
      {/* Logo Section */}
      <div className="flex items-center justify-center py-4">
        <img
          src={isCollapsed ? "https://d1tzewmx2l0uis.cloudfront.net/credilyst-small-logo.png" : "https://d1tzewmx2l0uis.cloudfront.net/credilyst-logo.png"}
          alt="Credilyst Logo"
          className={isCollapsed ? "w-6 h-6" : "w-[100px] h-[14px]"}
        />
      </div>

      {/* Expand/Collapse Button */}
      <div className="p-4">
        <button
          onClick={isCollapsed ? onExpandSidebar : onCollapseSidebar}
          className="focus:outline-none"
        >
          <img
            src={isCollapsed ? NavBarIconExpand : NavBarIconCollapse}
            className="w-6 h-6"
            alt={isCollapsed ? 'Expand' : 'Collapse'}
          />
        </button>
      </div>

      {/* Navigation Items */}
      <div>
        {navBarObject.map((item) => (
          <Link to={item.link} key={item.text}>
            <div
              className={`flex items-center py-3 px-6 ${
                isCollapsed ? 'justify-center' : 'gap-4'
              }`}
            >
              <img
                src={item.img}
                alt={item.text}
                className="w-[24px] h-[24px] flex-shrink-0" // Set explicit size
              />
              {!isCollapsed && (
                <p className="text-black font-inter text-sm">{item.text}</p>
              )}
            </div>
          </Link>
        ))}
      </div>

      {/* User Status/Logout at the bottom */}
      {!isCollapsed && (
        <div className="flex flex-col items-start mt-auto mb-4 px-6 w-full">
          <div className="flex items-center py-3 gap-4 w-full">
            <p className="text-black font-inter font-semibold text-sm">
              {userAttributes?.given_name && userAttributes?.family_name
                ? `Hello, ${userAttributes.given_name}`
                : 'Hello, User'}
            </p>
          </div>
          <div
            className="flex items-center py-3 gap-4 cursor-pointer"
            onClick={signOut}
          >
            <img
              src={LogOut}
              alt="Log Out"
              className="w-[24px] h-[24px] flex-shrink-0" // Explicit size for Log Out icon
            />
            <p className="text-black font-inter text-sm">Log Out</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
