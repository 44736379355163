// src/components/legal/legal-upload-modal.tsx
import React, { useState } from 'react';
import { apiCall } from '../../utils/api';
import Spinner from '../../images/DarkSpinner.gif'; // Assuming you have a spinner image

interface LegalUploadModalProps {
  dealName: string;
  clientName: string;
  onClose: () => void;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;
  onUploadSuccess: () => void; // Callback to refresh data or perform additional actions
}

const LegalUploadModal: React.FC<LegalUploadModalProps> = ({
  dealName,
  clientName,
  onClose,
  setToast,
  setToastMsg,
  setToastStatus,
  onUploadSuccess,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setSelectedFile(file || null);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      setToastMsg('Please select a file to upload.');
      setToastStatus(false);
      setToast(true);
      return;
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append('pdfFile', selectedFile);
      formData.append('clientName', clientName);
      formData.append('dealName', dealName);

      const response = await apiCall('/process-legal-document', {
        method: 'POST',
        data: formData,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      });

      // Log the response for debugging
      console.log('Process result:', response);

      // Directly access and check the message property
      if (response && response.message === 'File processed successfully') {
        console.log('Processing successful');
        setToastMsg('Legal document uploaded and processed successfully.');
        setToastStatus(true);
        setToast(true);
        onUploadSuccess(); // Refresh data or perform additional actions
        onClose(); // Close the modal after successful processing
      } else {
        console.log('Processing failed:', response);
        throw new Error(response?.message || 'Upload failed');
      }
      } catch (error: any) {
      console.error('Error during processing:', error);
      setToastMsg(error.message || 'Failed to upload and process the legal document.');
      setToastStatus(false);
      setToast(true);
      } finally {
      setUploading(false);
      }
    };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-4">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Upload Legal Document</h2>

        <div className="mb-4">
          <label htmlFor="fileUpload" className="block text-gray-700 font-medium mb-2">
            Select PDF File
          </label>
          <input
            id="fileUpload"
            type="file"
            onChange={handleFileChange}
            accept=".pdf"
            className="block w-full border border-gray-300 p-2 rounded"
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSubmit}
            className={`bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition duration-200 flex items-center ${
              uploading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={uploading}
          >
            {uploading && <img src={Spinner} alt="Uploading..." className="w-5 h-5 mr-2" />}
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded transition duration-200"
            disabled={uploading}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default LegalUploadModal;
