import React from 'react';

interface PageHeaderProps {
  title: string;
}
const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <div className='px-5 py-3 '>
      <h1 className='text-2xl text-black font-inter  w-full border-b border-b-black pb-1'>
        {title}
      </h1>
    </div>
  );
};

export default PageHeader;
