import React from 'react';

interface input extends React.InputHTMLAttributes<HTMLInputElement> {
  className: string;
  placeholder: string;
  type: string;
}
const Input: React.FC<input> = ({ className, placeholder, type, ...rest }) => {
  return (
    <>
      <input className={className} placeholder={placeholder} type={type} {...rest} />
    </>
  );
};

export default Input;
