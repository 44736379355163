// src/components/legal/legal-report-modal.tsx

import React, { useState } from 'react';
import Modal from 'react-modal';

interface LegalReportModalProps {
  isOpen: boolean;
  availableFiles: string[];
  onRequestClose: () => void;
  onGenerateReport: (selectedFile: string) => void;
}

Modal.setAppElement('#root'); // Ensure accessibility

const LegalReportModal: React.FC<LegalReportModalProps> = ({
  isOpen,
  availableFiles,
  onRequestClose,
  onGenerateReport,
}) => {
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleGenerate = () => {
    if (!selectedFile) {
      setError('Please select a file.');
      return;
    }
    onGenerateReport(selectedFile);
    setError('');
  };

  const handleClose = () => {
    setError('');
    setSelectedFile('');
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Generate Legal Report"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="relative w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 rounded-full"
          aria-label="Close Modal"
        >
          &times;
        </button>

        <h2 className="text-xl font-semibold mb-4 text-center">Generate Legal Report</h2>

        {/* File Selection */}
        <div className="mb-4">
          <label htmlFor="file-select" className="block font-medium mb-2">
            Select File
          </label>
          <select
            id="file-select"
            value={selectedFile}
            onChange={(e) => setSelectedFile(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded"
            disabled={availableFiles.length === 0} // Disable if no files
          >
            <option value="">-- Select a file --</option>
            {availableFiles.map((file) => (
              <option key={file} value={file}>
                {file}
              </option>
            ))}
          </select>
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition duration-150"
          >
            Cancel
          </button>
          <button
            onClick={handleGenerate}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-150"
            disabled={availableFiles.length === 0} // Disable if no files
          >
            Generate Report
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LegalReportModal;
