// src/components/monitoring/monitoring-table.tsx
import React, { useState, useEffect } from 'react';
import Button from '../ui/button/button';
import ToastMessage from '../ui/toastMessage/toast-message';
import Spinner from '../../images/DarkSpinner.gif'; // Import the spinner
import cancel_icon from '../../images/icon _cancel.svg'; // Import the delete icon
import InitializeMonitoringModal from './initialize-monitoring-modal';  // Ensure the path is correct
import ReportIcon from '../../images/report.svg';
import delete_icon from '../../images/delete-bin.svg';
import ReportModal from './report-modal'; // Adjust the path if the modal is in a different directory
import UpdateMonitoringModal from './update-monitoring-modal';  // Adjust the path if necessary
import file_upload from '../../images/icon_uploadmonitoring.svg';
import { apiCall } from '../../utils/api.ts';
import axios from 'axios';

interface MonitoringTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  clientName: string;
  setReportDate: (date: string) => void;
}

const MonitoringTable: React.FC<MonitoringTableProps> = ({
  data,
  setData,
  setHeader,
  companyName,
  setCompanyName,
  clientName,
  setReportDate,
}) => {
  // Store file, date, and frequency per row (company)
  const [file, setFile] = useState<{ [key: string]: File | null }>({});
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [initialized, setInitialized] = useState<{ [key: string]: boolean }>({});
  const [selectedDate, setSelectedDate] = useState<{ [key: string]: string }>({});
  const [selectedFrequency, setSelectedFrequency] = useState<{ [key: string]: string }>({});
  const [selectedNextReportDate, setSelectedNextReportDate] = useState<{ [key: string]: string }>({});
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reportGenerating, setReportGenerating] = useState<{ [key: string]: boolean }>({});
  const [selectedCompany, setSelectedCompany] = useState<string>('');  // Track the selected company
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReportDate, setSelectedReportDate] = useState<string>('');  // Store the selected report date
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  console.log('Rendering MonitoringTable with data:', data);

  useEffect(() => {
    console.log('Running useEffect, clientName:', clientName, 'data:', data);  // Log to verify clientName and data
  
    if (!clientName || !data || data.length === 0) {
      console.log('Missing clientName or data is empty');
      return;  // Exit early if clientName or data is missing
    }

    const fetchMostRecentDocument = async (item: string) => {
      try {
        const mostRecentDocument  = await apiCall(`/most-recent-document?collectionName=${clientName}&companyName=${item}`);

        console.log('Most Recent Document:', mostRecentDocument);

        if (mostRecentDocument) {
          setSelectedDate(prev => ({ ...prev, [item]: mostRecentDocument.report_date }));
          setSelectedFrequency(prev => ({ ...prev, [item]: mostRecentDocument.report_frequency }));
          setSelectedNextReportDate(prev => ({ ...prev, [item]: mostRecentDocument.nextreport_date }));
        }
      } catch (error) {
        console.error('Failed to fetch the most recent document:', error);
      }
    };
  
    // Loop through data and fetch for each item (company)
    data.forEach(item => {
      fetchMostRecentDocument(item);
    });
  }, [clientName, data]);

  /**
   * Updated handleSelectReportDate to accept both reportDate and format
   * @param reportDate The selected report date
   * @param format The selected report format ('pdf' or 'word')
   */
  const handleSelectReportDate = async (reportDate: string, format: string) => {
    setSelectedReportDate(reportDate); // Track the selected report date

    try {
      setReportGenerating(prev => ({ ...prev, [selectedCompany]: true })); // Start spinner

      const response = await apiCall(`/generate-monitoring-report`, {
        method: 'POST',
        data: {
          collectionName: clientName, // Ensure clientName is passed as collectionName
          companyName: selectedCompany,
          reportDate: reportDate,
          format: format, // Include the format
        },
        responseType: 'blob', // Set response type for file download
        transformResponse: (res: any) => res, // Disable automatic transformation
      });
      console.log('Blob data:', response.data);

      // Determine the file extension and MIME type based on the format
      const fileExtension = format === 'word' ? 'docx' : 'pdf';
      const mimeType = format === 'word' 
        ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
        : 'application/pdf';
      const filename = `${selectedCompany}-${reportDate}-Monitoring-Report.${fileExtension}`;

      // Create a new Blob from the response data
      const blob = new Blob([response.data], { type: mimeType });

      // Create a URL for the Blob and initiate download
      const url = window.URL.createObjectURL(blob);
      console.log('Generated URL:', url);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url); // Revoke the Object URL after download
      
      setShowToast(true);
      setToastMsg(`Report generated successfully as ${fileExtension.toUpperCase()}`);
      setToastStatus(true);   
    
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) { // Check if the error is an AxiosError
        console.error('Failed to generate report:', err);

        if (err.response) {
          console.error('Error Status:', err.response.status);
          console.error('Error Data:', err.response.data);
          console.error('Error Headers:', err.response.headers);
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error Message:', err.message);
        }
      } else {
        // Handle any other errors not related to Axios
        console.error('An unknown error occurred:', err);
      }

      setToastMsg('Failed to generate report');
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setReportGenerating(prev => ({ ...prev, [selectedCompany]: false })); // Stop the spinner
    }
  };

  const openModal = (companyName: string) => {
    setSelectedCompany(companyName);
    setShowUpdateModal(true);  // Always open the update modal
  };

  // Handle file selection per row
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const files = e.target.files;
    if (files && files.length > 0) { // Ensure files are not null and there's at least one file
      setFile((prev) => ({ ...prev, [name]: files[0] }));
    }
  };

  // Handle spinner for generate report
  const openGenerateReportModal = (companyName: string) => {
    setSelectedCompany(companyName);  // Track the company for which the report is being generated
    setShowReportModal(true);  // Open the report modal
  };

  const handleCloseModal = () => {
    setShowModal(false);  // Close the modal
    setReportGenerating((prev) => ({ ...prev, [selectedCompany]: false }));  // Stop the spinner
  };

  //  Handle double-click event
  const handleRowDoubleClick = (name: string, reportDate: string) => {
    setCompanyName(name); // Set the selected company name for monitoring
    setHeader(name); // Change the header to the company’s monitoring page
    setReportDate(reportDate);
  };

  // Handle delete file per row
  const handleDeleteFile = (name: string) => {
    setFile((prev) => ({ ...prev, [name]: null }));
  };

  // Handle date selection per row
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setSelectedDate((prev) => ({ ...prev, [name]: e.target.value }));
  };

  // Handle frequency change per row
  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    setSelectedFrequency((prev) => ({ ...prev, [name]: e.target.value }));
  };

  // Initialize the deal (send the file to the backend using fetch)
  const initializeMonitoring = async (name: string) => {
    if (!file[name]) {
      alert('Please select a file to initialize');
      return;
    }

    setUploading((prev) => ({ ...prev, [name]: true }));

    try {
      const formData = new FormData();
      formData.append('clientName', clientName);
      formData.append('companyName', name);
      formData.append('pdfFile', file[name]!); // Add the file to the form data
      formData.append('pdfName', file[name]!.name); // Add the file name
      formData.append('reportDate', selectedDate[name] || ''); // Add selected date
      formData.append('reportFrequency', selectedFrequency[name] || 'Quarterly'); // Add selected frequency

      // Send the POST request using fetch
      const response = await apiCall('/initialize-monitoring', {
        method: 'POST',
        data: formData,  // Use 'data' instead of 'body'
        // headers: {
        //   'Content-Type': 'multipart/form-data',  // Ensure correct headers
        // },
      });
  
      const result = response;  // Access data directly

      if (response.status === 200 && result.message === 'Monitoring initialized and skeleton created successfully') {
        setInitialized((prev) => ({ ...prev, [name]: true }));
        setShowToast(true);
        setToastMsg('Deal initialized successfully');
        setToastStatus(true);
      } else {
        throw new Error('Initialization failed');
      }
    } catch (error) {
      setShowToast(true);
      setToastMsg('Failed to initialize monitoring');
      setToastStatus(false);
      console.error('Error initializing monitoring:', error);
    } finally {
      setUploading((prev) => ({ ...prev, [name]: false }));
    }
  };

  return (
    <div className="w-full">
      <table className='table-auto w-full border-gray-300 border rounded'>
        <thead>
          <tr className='border-b border-gray-300'>
            <th className='bg-gray-100 p-3 border-r border-gray-300 font-semibold'>Company Name</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Latest Report</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Next Report Due</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Frequency</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Delete</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Upload</th>
            <th className='bg-gray-100 p-3 font-semibold'>Report</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className="cursor-pointer hover:bg-gray-200" // Add hover effect for better UI experience
              onDoubleClick={() => handleRowDoubleClick(item, selectedDate[item] || '')} // Add double click handler
            >
              <td className='px-3 py-2 border-r border-gray-300'>{item}</td>
              <td className='px-3 py-2 border-r border-gray-300'>
                {selectedDate[item] || 'No reports yet'}
              </td>
              <td className='px-3 py-2 border-r border-gray-300'>
                {selectedNextReportDate[item] || 'N/A'}
              </td>
              <td className='px-3 py-2 border-r border-gray-300'>
                {selectedFrequency[item] || 'N/A'}
              </td>
              <td className='px-3 py-2 border-r border-gray-300 text-center'>
                <img
                  src={delete_icon}
                  alt="Delete Monitoring"
                  className='cursor-pointer'
                  style={{ display: 'block', margin: '0 auto' }}
                  onClick={() => handleDeleteFile(item)}  // Ensure this function deletes the monitoring or file for the row
                />
              </td>
              <td className='px-3 py-2 border-r border-gray-300 text-center'>
                <img
                  src={file_upload}
                  alt="Upload Monitoring"
                  className='cursor-pointer'
                  style={{ display: 'block', margin: '0 auto' }}
                  onClick={() => openModal(item)}  // Directly open the update modal
                />
              </td>

              {/* Initialize Button with Spinner */}
              <td className='px-3 py-2 text-center'>
                {reportGenerating[item] ? (
                  <img src={Spinner} alt="Loading..." className='w-8 h-8' style={{ display: 'block', margin: '0 auto' }} />
                ) : (
                  <img
                    src={ReportIcon}
                    alt="Generate Report"
                    className='cursor-pointer'
                    style={{ display: 'block', margin: '0 auto' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the row click event
                      openGenerateReportModal(item); // Trigger report generation
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {showReportModal && (
        <ReportModal
          setShowModal={setShowReportModal}
          clientName={clientName}
          companyName={selectedCompany}
          onSelectReportDate={handleSelectReportDate}  // Pass the updated handler
        />
      )}

      {showUpdateModal && (
        <UpdateMonitoringModal
          setShowModal={setShowUpdateModal}
          clientName={clientName}
          companyName={selectedCompany}
          nextReportDate={selectedDate[selectedCompany] || ''}  // Pass the next report date
        />
      )}

      {/* Toast Message */}
      {showToast && (
        <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
      )}
    </div>
  );
};

export default MonitoringTable;
