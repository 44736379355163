import React from 'react';
import Layout from '../components/ui/layout/layout';
import NewProjectMain from '../components/new poject/new-project-main';

const NewProjectPage = () => {
  return (
    <Layout>
      <NewProjectMain />
    </Layout>
  );
};

export default NewProjectPage;
