// src/config/get-app-config.ts
import * as Joi from 'joi';

export interface ApplicationConfig {
  cognitoClientID: string;
  cognitoDomainName: string;
  cognitoUserPoolID: string;
  frontendBaseURL: string;
}

export const getAppConfig = (): ApplicationConfig => {
  const config = {
    cognitoClientID: process.env.REACT_APP_COGNITO_CLIENT_ID,
    cognitoDomainName: process.env.REACT_APP_COGNITO_DOMAIN_NAME,
    cognitoUserPoolID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    frontendBaseURL: process.env.REACT_APP_API_BASE_URL || '',
  };

  const validationSchema = Joi.object<ApplicationConfig>({
    cognitoClientID: Joi.string().required(),
    cognitoDomainName: Joi.string().required(),
    cognitoUserPoolID: Joi.string().required(),
    frontendBaseURL: Joi.string().required(),
  });

  const { error, value } = validationSchema.validate(config, {
    abortEarly: true,
  });

  if (error) {
    throw new Error(
      `[Application Config]: Environment validation failed. Please review your .env file. Error: ${error.message}`,
    );
  }

  return value;
};
