// src\components\legal\initialize-monitoring.tsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { apiCall } from '../../utils/api';
import ToastMessage from '../ui/toastMessage/toast-message';
import Spinner from '../../images/DarkSpinner.gif';

Modal.setAppElement('#root'); // Ensure accessibility

interface InitializeSFAModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  clientName: string;
  dealName: string;
}

const InitializeSFAModal: React.FC<InitializeSFAModalProps> = ({
  isOpen,
  onRequestClose,
  clientName,
  dealName,
}) => {
  const [availableFiles, setAvailableFiles] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);

  /**
   * Fetch available files when the modal opens
   */
  useEffect(() => {
    if (isOpen) {
      fetchAvailableFiles();
    } else {
      // Reset state when modal closes
      setAvailableFiles([]);
      setSelectedFile('');
      setShowToast(false);
      setToastMsg('');
      setToastStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchAvailableFiles = async () => {
    setIsLoadingFiles(true);
    try {
      const response = await apiCall(`/get-legal-files/${clientName}`, {
        method: 'GET',
        params: { dealName }, // Pass dealName if necessary
      });

      // Assuming response contains a list of filenames under `filenames`
      setAvailableFiles(response.filenames || []);
      if (response.filenames && response.filenames.length > 0) {
        setSelectedFile(response.filenames[0]); // Default to first file
      } else {
        setToastMsg('No available files found for this deal.');
        setToastStatus(false);
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error fetching available files:', error);
      setAvailableFiles([]);
      setToastMsg('Failed to fetch available files.');
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setIsLoadingFiles(false);
    }
  };

  const handleInitializeSFA = async () => {
    if (!selectedFile) {
      setToastMsg('Please select a file to initialize SFA.');
      setToastStatus(false);
      setShowToast(true);
      return;
    }

    setIsInitializing(true);

    try {
      const response = await apiCall('/initialize-sfa', {
        method: 'POST',
        data: {
          clientName,
          dealName,
          filename: selectedFile, // Send filename instead of file
        },
      });

      if (response.success) {
        setToastMsg('SFA initialized successfully.');
        setToastStatus(true);
        setShowToast(true);
        onRequestClose(); // Close the modal on success
      } else {
        throw new Error(response.message || 'Initialization failed.');
      }
    } catch (error: any) {
      console.error('Error initializing SFA:', error);
      setToastMsg(`Failed to initialize SFA: ${error.message || 'Unknown error'}`);
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setIsInitializing(false);
    }
  };

  const handleClose = () => {
    setAvailableFiles([]);
    setSelectedFile('');
    setShowToast(false);
    setToastMsg('');
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Initialize SFA"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="relative w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 rounded-full"
          aria-label="Close Modal"
        >
          &times;
        </button>

        <h2 className="text-xl font-semibold mb-4 text-center">Initialize SFA</h2>

        {/* File Selection Dropdown */}
        <div className="mb-4">
          <label htmlFor="sfa-file-select" className="block font-medium mb-2">
            Select Legal File
          </label>
          {isLoadingFiles ? (
            <div className="flex items-center justify-center">
              <img src={Spinner} alt="Loading..." className="w-6 h-6" />
              <span className="ml-2">Loading files...</span>
            </div>
          ) : availableFiles.length > 0 ? (
            <select
              id="sfa-file-select"
              value={selectedFile}
              onChange={(e) => setSelectedFile(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded"
            >
              {availableFiles.map((file, index) => (
                <option key={index} value={file}>
                  {file}
                </option>
              ))}
            </select>
          ) : (
            <p className="text-red-500">No files available to select.</p>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition duration-150"
            disabled={isInitializing}
          >
            Cancel
          </button>
          <button
            onClick={handleInitializeSFA}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-150"
            disabled={isInitializing || isLoadingFiles || availableFiles.length === 0}
          >
            {isInitializing ? 'Initializing...' : 'Initialize SFA'}
          </button>
        </div>

        {/* Toast Message */}
        {showToast && (
          <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
        )}
      </div>
    </Modal>
  );
};

export default InitializeSFAModal;
