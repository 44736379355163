// src/components/new-project/new-project-main.tsx

import React, { useState, useEffect } from 'react';
import Input from '../ui/input/input';
import Button from '../ui/button/button';
import PageHeader from '../ui/pageHeader/pageHeader';
import ListOfFile from './listOfFile';
import FileUploadArea from './fileUploadArea';
import { useQueryClient } from '@tanstack/react-query';
import ToastMessage from '../ui/toastMessage/toast-message';
import { getClientName } from '../../utils/authUtils';
import { apiCall } from '../../utils/api';
import Spinner from '../../images/DarkSpinner.gif';

const NewProjectMain = () => {
  const [projectName, setProjectName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [clientName, setClientName] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchClientName = async () => {
      try {
        const name = await getClientName();
        setClientName(name);
      } catch (error) {
        console.error('Failed to fetch client name:', error);
        setClientName(null);
      }
    };

    fetchClientName();
  }, []);

  const removeFile = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleUpload = async () => {
    setUploading(true);
    console.log('Upload started');

    try {
      if (!clientName) {
        console.error('Client name is not available. Please log in again.');
        setUploading(false);
        setShowToast(true);
        setToastMsg('Client name is not available. Please log in again.');
        setToastStatus(false);
        return;
      }

      const formData = new FormData();
      files.forEach((f) => formData.append('files', f));
      formData.append('projectName', projectName);
      formData.append('companyName', companyName);
      formData.append('clientName', clientName);

      const response = await apiCall('/upload-to-s3', {
        method: 'POST',
        data: formData,
      });

      console.log('Upload result:', response);

      if (response.success) {
        console.log('Upload successful, resetting state');
        setFiles([]);
        setProjectName('');
        setCompanyName('');
        setUploading(false);
        queryClient.invalidateQueries({ queryKey: ['project-table-data'] });
        setShowToast(true);
        setToastMsg('Project created successfully');
        setToastStatus(true);
      } else {
        console.log('Upload failed:', response.success);
        setUploading(false);
        setShowToast(true);
        setToastMsg(response.success || 'Something went wrong');
        setToastStatus(false);
      }
    } catch (error) {
      console.error('Error during upload:', error);
      setUploading(false);
      setShowToast(true);
      setToastMsg('Something went wrong');
      setToastStatus(false);
    }
  };

  return (
    <div className="py-2 pl-4 font-inter w-full overflow-hidden">
      <PageHeader title="New Project" />
      <div className="flex flex-col justify-between h-[calc(100vh_-_77px)] overflow-auto pr-4">
        <div className="w-full flex flex-col items-center">
          {/* Input Forms */}
          <div className="flex flex-col md:flex-row w-full justify-center items-center gap-4 mt-12 mb-8">
            <div className="w-full md:w-1/3">
              <Input
                className="p-3 font-inter text-black border border-black text-lg w-full rounded-md"
                placeholder="Project Name"
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                disabled={uploading}
              />
            </div>
            <div className="w-full md:w-1/3">
              <Input
                className="p-3 font-inter text-black border border-black text-lg w-full rounded-md"
                placeholder="Company Name"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                disabled={uploading}
              />
            </div>
          </div>
          {/* Upload Instructions */}
          <p className="text-black font-inter text-2xl pb-4">
            Upload your due diligence package
          </p>
          {/* File Upload Area */}
          <FileUploadArea files={files} setFiles={setFiles} disabled={uploading} />
          {/* List of Files */}
          {files.length > 0 && (
            <ListOfFile files={files} removeFile={removeFile} />
          )}
        </div>
        {/* Action Buttons */}
        <div className="p-4 flex justify-end gap-3">
          <Button
            className="p-2 font-inter text-lg border border-gray-100 bg-gray-500 rounded-2xl text-gray-200 hover:bg-gray-600 transition-all duration-200"
            label="Cancel"
            onClick={() => {
              setFiles([]);
              setProjectName('');
              setCompanyName('');
            }}
            disabled={uploading}
          />
          <Button
            className={`p-2 font-inter text-lg border border-gray-100 rounded-2xl text-white flex gap-1 items-center transition-all duration-200 ${
              files.length === 0 ||
              projectName.trim().length === 0 ||
              companyName.trim().length === 0 ||
              uploading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
            label={uploading ? 'Uploading...' : 'Upload'}
            disabled={
              files.length === 0 ||
              projectName.trim().length === 0 ||
              companyName.trim().length === 0 ||
              uploading
            }
            onClick={handleUpload}
            loading={uploading}
          />
        </div>
      </div>
      {/* Toast Message */}
      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          toastMsg={toastMsg}
          setToast={setShowToast}
        />
      )}
    </div>
  );
};

export default NewProjectMain;
