// src/components/legal/legal-table.tsx

import React, { useState } from 'react';
import { apiCall } from '../../utils/api.ts';
import Spinner from '../../images/DarkSpinner.gif';
import ReportIcon from '../../images/report.svg';
import ExcelIcon from '../../images/excel-icon.svg'; // Ensure you have this icon in the specified path
import delete_icon from '../../images/delete-bin.svg';
import file_upload from '../../images/icon_uploadmonitoring.svg';
import initialize_icon from '../../images/icon_initialize.svg';
import LegalUploadModal from './legal-upload-modal';
import LegalReportModal from './legal-report-modal';
import ToastMessage from '../ui/toastMessage/toast-message';
import InitializeSFAModal from './initialize-SFA.tsx'; // Import InitializeSFAModal

interface LegalTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  clientName: string;
  showToast: boolean; // Add this
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  toastMsg: string; // Add this
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  toastStatus: boolean; // Add this
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setDealName: React.Dispatch<React.SetStateAction<string>>;
}

const LegalTable: React.FC<LegalTableProps> = ({
  data,
  setData,
  clientName,
  showToast,
  setToast,
  toastMsg,
  setToastMsg,
  toastStatus,
  setToastStatus,
  setHeader,
  setDealName,
}) => {
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [reportGenerating, setReportGenerating] = useState<{ [key: string]: boolean }>({});
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = useState<string>('');
  const [availableFiles, setAvailableFiles] = useState<string[]>([]); // List of available files
  const [showInitializeSFAModal, setShowInitializeSFAModal] = useState<boolean>(false); // State for InitializeSFAModal
  const [selectedSFADeal, setSelectedSFADeal] = useState<string>(''); // Deal selected for SFA initialization

  /**
   * Open the report modal and fetch available filenames for the selected deal
   */
  const handleGenerateReportClick = (dealName: string) => {
    openReportModal(dealName);
  };

  const openReportModal = async (dealName: string) => {
    setSelectedDeal(dealName);
    setShowReportModal(true); // Open modal unconditionally

    try {
      const response = await apiCall(`/get-legal-files/${clientName}`, {
        method: 'GET',
        params: { dealName }, // Send dealName as a query parameter
      });

      // Assuming response contains a list of filenames under `filenames`
      setAvailableFiles(response.filenames || []);
    } catch (error) {
      console.error('Error fetching available files:', error);
      setAvailableFiles([]); // Clear available files on error
      setToastMsg('Failed to fetch available files.');
      setToastStatus(false);
      setToast(true);
    }
  };

  /**
   * Handle the generation of the report with the selected file
   */
  const handleGenerateReport = async (selectedFile: string) => {
    setReportGenerating((prev) => ({ ...prev, [selectedDeal]: true }));
    try {
      const response = await apiCall(`/generate-legal-report`, {
        method: 'POST',
        data: {
          clientName,
          dealName: selectedDeal,
          selectedFile,
        },
        responseType: 'blob',
        transformResponse: (res: any) => res, // Disable automatic transformation
      });

      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      // Create a URL for the file and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedDeal}-Legal-Report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Error generating legal report:', error);
      setToastMsg('Failed to generate legal report.');
      setToastStatus(false);
      setToast(true);
    } finally {
      setReportGenerating((prev) => ({ ...prev, [selectedDeal]: false }));
      setShowReportModal(false);
      setSelectedDeal('');
    }
  };

  /**
   * Handle initializing SFA for a deal
   */
  const handleInitializeSFA = (dealName: string) => {
    setSelectedSFADeal(dealName);
    setShowInitializeSFAModal(true);
  };

  /**
   * Handle deleting a deal
   */
  const handleDeleteDeal = async (dealName: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setToast(true);
      return;
    }

    // Confirmation dialog
    if (window.confirm(`Are you sure you want to delete the deal: ${dealName}?`)) {
      try {
        const response = await apiCall(`/collection/${clientName}/company/${dealName}`, { method: 'DELETE' });
        if (response.status === 200) {
          const newData = data.filter((item) => item !== dealName);
          setData(newData);
          setToastMsg('Deal deleted successfully');
          setToastStatus(true);
          setToast(true);
        }
      } catch (error) {
        setToastMsg('Something went wrong');
        setToastStatus(false);
        setToast(true);
      }
    }
  };

  /**
   * Handle double-clicking a row to analyze legal documents
   */
  const handleRowDoubleClick = (dealName: string) => {
    setHeader(`Analyze Legal Docs: ${dealName}`);
    setDealName(dealName);
  };

  /**
   * Handle closing the Compare SFAs modal
   */
  const handleCloseInitializeSFAModal = () => {
    setShowInitializeSFAModal(false);
    setSelectedSFADeal('');
  };

  return (
    <div className='w-full text-center'>
      <table className='font-inter w-full border-gray-300 border rounded'>
        <thead>
          <tr className='border-b border-gray-300'>
            <th className='bg-gray-100 p-3 border-r border-gray-300 font-semibold'>Deal Name</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Upload</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Delete</th>
            <th className='bg-gray-100 p-3 font-semibold border-r border-gray-300'>Key Terms</th>
            <th className='bg-gray-100 p-3 font-semibold'>SFA</th> {/* SFA Column */}
          </tr>
        </thead>
        <tbody>
          {data.map((deal, index) => (
            <tr
              key={index}
              className='hover:bg-gray-200 cursor-pointer'
              onDoubleClick={() => handleRowDoubleClick(deal)}
            >
              <td className='px-3 py-2 text-center'>{deal}</td>
              <td className='px-3 py-2 text-center'>
                {uploading[deal] ? (
                  <img src={Spinner} alt="Uploading..." className="w-5 h-5 mx-auto" />
                ) : (
                  <img
                    src={file_upload}
                    alt="Upload Legal Documents"
                    className="cursor-pointer w-5 h-5 mx-auto"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Open upload modal
                      setSelectedDeal(deal);
                      setShowUploadModal(true);
                    }}
                  />
                )}
              </td>
              <td className='px-3 py-2 text-center'>
                <img
                  src={delete_icon}
                  alt="Delete"
                  className="cursor-pointer w-5 h-5 mx-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteDeal(deal);
                  }}
                />
              </td>
              <td className='px-3 py-2 text-center'>
                {reportGenerating[deal] ? (
                  <img src={Spinner} alt="Generating..." className='w-8 h-8 mx-auto' />
                ) : (
                  <img
                    src={ExcelIcon} // Swapped ReportIcon with ExcelIcon
                    alt="Generate Excel Report"
                    className='cursor-pointer w-5 h-5 mx-auto' // Adjusted the class to maintain consistent size
                    style={{ display: 'block', margin: '0 auto' }} // Ensure consistent alignment
                    onClick={(e) => {
                      e.stopPropagation();
                      handleGenerateReportClick(deal);
                    }}
                  />
                )}
              </td>
              <td className='px-3 py-2 text-center'>
                {/* Initialize SFA Icon */}
                <img
                  src={initialize_icon} // Replace with an appropriate icon for Initialize SFA
                  alt="Initialize SFA"
                  className="cursor-pointer w-5 h-5 mx-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInitializeSFA(deal);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Upload Modal */}
      {showUploadModal && (
        <LegalUploadModal
          dealName={selectedDeal}
          clientName={clientName}
          onClose={() => {
            setShowUploadModal(false);
            setSelectedDeal('');
          }}
          setToast={setToast}
          setToastMsg={setToastMsg}
          setToastStatus={setToastStatus}
          onUploadSuccess={() => {
            setToastMsg('Legal document uploaded successfully.');
            setToastStatus(true);
            setToast(true);
          }}
        />
      )}

      {/* Generate Report Modal */}
      {showReportModal && (
        <LegalReportModal
          isOpen={showReportModal}
          availableFiles={availableFiles}
          onRequestClose={() => setShowReportModal(false)}
          onGenerateReport={handleGenerateReport}
        />
      )}

      {/* Initialize SFAs Modal */}
      {showInitializeSFAModal && (
        <InitializeSFAModal
          isOpen={showInitializeSFAModal}
          onRequestClose={handleCloseInitializeSFAModal}
          clientName={clientName}
          dealName={selectedSFADeal}
          // You can pass additional props if needed
        />
      )}

      {/* Toast Message */}
      {showToast && (
        <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setToast} />
      )}
    </div>
  );
};

export default LegalTable;
