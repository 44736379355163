// src/utils/authUtils.ts
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

// Interface for user attributes (if needed)
interface UserAttribute {
  Name: string;
  Value: string;
}
// Function to fetch the custom:Client_Name attribute
export async function getClientName(): Promise<string | null> {
    try {
        const attributes = await fetchUserAttributes(); // Fetch attributes
        console.log('Fetched User Attributes:', attributes); // Debugging line

        // Extract 'custom:Client_Name' from the attributes object
        const clientName = attributes['custom:Client_Name'];

        if (!clientName) {
            console.error('Client_Name attribute not found in user attributes.');
            return null;
        }

        console.log("Client Name:", clientName);
        return clientName;
    } catch (error) {
        console.error('Error fetching user attributes:', error);
        return null;
    }
}

// Function to fetch the access token from the current session
export async function getAccessToken(): Promise<string | null> {
  try {
    const session = await fetchAuthSession(); // Fetch the current session
    if (!session.tokens) {
      console.error('No tokens found in session.');
      return null;
    }

    const accessToken = session.tokens.accessToken;
    if (!accessToken) {
      console.error('Access token not found in session.');
      return null;
    }

    // Correctly retrieve the JWT token string using toString()
    const accessTokenString = accessToken.toString();

    if (typeof accessTokenString !== 'string') {
      console.error('Access token is not a string.');
      return null;
    }

    // console.log("Access Token:", accessTokenString);
    return accessTokenString;
  } catch (error) {
    console.error('Error fetching access token:', error);
    return null;
  }
}