// src/components/new-project/fileUploadArea.tsx

import React from 'react';
import DropZone from './dropZone/dropZone';

interface FileUploadAreaProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  disabled: boolean;
}

const FileUploadArea: React.FC<FileUploadAreaProps> = ({ files, setFiles, disabled }) => {
  return (
    <div className="w-full max-w-xl">
      <DropZone file={setFiles} disabled={disabled} />
    </div>
  );
};

export default FileUploadArea;
