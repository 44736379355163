import React from 'react';
import { FinancialData } from '../../types/skeletonTypes';

interface SummaryTableProps {
  reportDataList: FinancialData[];
}

const SummaryTable: React.FC<SummaryTableProps> = ({ reportDataList }) => {
  // Filter out entries that have both report_date and performance_summary and sort by report_date descending
  const summaryData = reportDataList
    .filter((data) => data?.report_date && data?.performance_summary)
    .sort((a, b) => new Date(b?.report_date ?? 0).getTime() - new Date(a?.report_date ?? 0).getTime());

  if (summaryData.length === 0) {
    return (
      <div className="bg-white shadow rounded-lg p-4 max-w-full overflow-hidden">
        <p className="text-gray-500">No summary data available for the selected period.</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-4 max-w-full">
      <h3 className="text-xl font-semibold mb-4">Performance Summary</h3>
      <table className="table-auto divide-y divide-gray-200" style={{ width: '100%' }}>
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              style={{ width: '20%' }}
            >
              Report Date
            </th>
            <th
              scope="col"
              className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
              style={{ maxWidth: '500px' }}
            >
              Performance Summary
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {summaryData.map((data, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">
                {new Date(data?.report_date ?? 0).toLocaleDateString()}
              </td>
              <td
                className="px-4 py-2 whitespace-normal text-sm text-gray-700"
                style={{ maxWidth: '500px', wordWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {data?.performance_summary}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryTable;
