// src/components/legal/DataTableCompareSFAs.tsx

import React from 'react';
import EditableCellLegal from '../EditableCellLegal'; // Import the legal-specific EditableCell
import classNames from 'classnames';

interface DataTableCompareSFAsProps {
  sfaSummaries: any[]; // Array of SFA_Summary documents
  handleSave: (filename: string, fieldPath: string, newValue: string) => void;
  modifiedCells: Set<string>;
  handleCellModify: (filename: string, fieldPath: string) => void;
}

const DataTableCompareSFAs: React.FC<DataTableCompareSFAsProps> = ({
  sfaSummaries,
  handleSave,
  modifiedCells,
  handleCellModify,
}) => {
  if (sfaSummaries.length === 0) {
    return <div>No SFA_Summary documents found.</div>;
  }

  // Assuming all SFA_Summary documents have the same structure
  // Get all sections from the first document
  const sections = Object.keys(sfaSummaries[0]).filter(
    (key) => !['_id', 'company_name', 'filename', 'type'].includes(key)
  );

  // Collect all fields under each section
  const sectionFields: { [section: string]: string[] } = {};

  sections.forEach((section) => {
    sectionFields[section] = Object.keys(sfaSummaries[0][section]);
  });

  return (
    <div className="overflow-x-auto">
      {/* Scrollable container with fixed height */}
      <div className="max-h-[600px] overflow-y-auto">
        <table className="min-w-full border border-gray-300">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              {/* First Column: Section */}
              <th className="w-32 px-4 py-3 border-r border-gray-300 text-left font-bold text-sm">
                Section
              </th>
              {/* Second Column: Field */}
              <th className="w-48 px-4 py-3 border-r border-gray-300 text-left font-bold text-sm">
                Field
              </th>
              {/* Dynamic Columns: Filenames */}
              {sfaSummaries.map((sfa, index) => (
                <th
                  key={index}
                  className="px-4 py-3 border-r border-gray-300 text-center font-bold text-sm"
                >
                  {sfa.filename}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sections.map((section) => (
              <React.Fragment key={section}>
                {sectionFields[section].map((field) => (
                  <tr key={field}>
                    {/* Section Cell: Only on the first field of the section */}
                    {field === sectionFields[section][0] && (
                      <td
                        className="w-32 px-4 py-3 border-r border-gray-300 font-bold align-top text-sm"
                        rowSpan={sectionFields[section].length}
                        style={{ verticalAlign: 'top' }}
                      >
                        {section}
                      </td>
                    )}
                    {/* Field Cell */}
                    <td
                      className="w-48 px-4 py-3 border-r border-gray-300 align-top text-sm break-words"
                      title={field} // Show full text on hover
                    >
                      {field}
                    </td>
                    {/* Editable Cells */}
                    {sfaSummaries.map((sfa) => {
                      const value = sfa[section][field];
                      const cellKey = `${sfa.filename}-${section}.${field}`;
                      const isModified = modifiedCells.has(cellKey);
                      return (
                        <td
                          key={`${sfa.filename}-${field}`}
                          className={classNames('px-4 py-3 border-r border-gray-300 align-top', {
                            'bg-yellow-100': isModified,
                          })}
                          style={{ verticalAlign: 'top' }}
                        >
                          <EditableCellLegal
                            value={value}
                            onSave={(newValue) => handleSave(sfa.filename, `${section}.${field}`, newValue)}
                            onModify={() => handleCellModify(sfa.filename, `${section}.${field}`)}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTableCompareSFAs;
