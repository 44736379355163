// src/pages/legalPage.tsx
import React from 'react';
import Layout from '../components/ui/layout/layout';
import LegalMain from '../components/legal/legal-main';

const LegalPage = () => {
  return (
    <Layout>
      <LegalMain />
    </Layout>
  );
};

export default LegalPage;
