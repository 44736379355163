// src/components/monitoring/charts/BarChartComponent.tsx
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface BarConfig {
  dataKey: string;
  fill: string;
}

interface BarChartComponentProps {
  data: { name: string; [key: string]: number | string }[];
  bars: BarConfig[];
  height?: number;
  width?: string | number;
  margin?: { top: number; right: number; left: number; bottom: number };
}

const formatYAxis = (value: number): string => {
    if (value >= 1_000_000_000) return `${(value / 1_000_000_000).toFixed(1)}B`;
    if (value >= 1_000_000) return `${(value / 1_000_000).toFixed(1)}M`;
    if (value >= 1_000) return `${(value / 1_000).toFixed(1)}K`;
    return value.toString();
  };
  

const BarChartComponent: React.FC<BarChartComponentProps> = ({
  data,
  bars,
  height = 200,
  width = '90%',
  margin = { top: 10, right: 10, left: 10, bottom: 5 },
}) => {
  return (
    <ResponsiveContainer width={width} height="90%">
      <BarChart data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 10 }} />
        <YAxis tickFormatter={formatYAxis} tick={{ fontSize: 10 }} />
        <Tooltip formatter={(value: any) => formatYAxis(Number(value))} />
        <Legend wrapperStyle={{ fontSize: 10 }} />
        {bars.map((bar) => (
          <Bar key={bar.dataKey} dataKey={bar.dataKey} fill={bar.fill} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
