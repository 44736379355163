import React from 'react';
import Modal from 'react-modal';
import { apiCall } from '../../utils/api'; // Import your API utility

interface Paragraph {
  section: string;
  questions: string[];
  isSubsection: boolean;
}

interface FileSelectionModalProps {
  isModalOpen: boolean;
  clientName: string;
  companyName: string;
  closeModal: () => void;
  handleGenerateDeal: (
    format: string,
    reportType: string,
    selectedFiles: { [section: string]: string[] }
  ) => void;
}

Modal.setAppElement('#root');

const FileSelectionModal: React.FC<FileSelectionModalProps> = ({
  isModalOpen,
  clientName,
  companyName,
  closeModal,
  handleGenerateDeal,
}) => {
  const [currentStep, setCurrentStep] = React.useState<'selectReportType' | 'selectFiles'>(
    'selectReportType'
  );
  const [selectedReportType, setSelectedReportType] = React.useState<'short' | 'long'>('short');
  const [selectedFormat, setSelectedFormat] = React.useState<'pdf' | 'word'>('pdf');
  const [paragraphs, setParagraphs] = React.useState<Paragraph[]>([]);
  const [availableFiles, setAvailableFiles] = React.useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<{ [section: string]: string[] }>({});
  const [errors, setErrors] = React.useState<{ [section: string]: string }>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchParagraphsAndFiles = async () => {
      setIsLoading(true);
      try {
        const response = await apiCall(
          `/filenames-and-questions/${encodeURIComponent(clientName)}`,
          {
            params: {
              companyName: companyName,
              reportType: selectedReportType,
            },
          }
        );

        const { paragraphs, filenames } = response;
        const filteredParagraphs = paragraphs.filter(
          (paragraph: Paragraph) => !paragraph.isSubsection
        );
        setParagraphs(filteredParagraphs);
        setAvailableFiles(filenames);
      } catch (error) {
        console.error('Error fetching filenames and questions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentStep === 'selectFiles') {
      fetchParagraphsAndFiles();
    }
  }, [currentStep, selectedReportType, clientName, companyName]);

  const handleNext = () => {
    setCurrentStep('selectFiles');
  };

  const handleBack = () => {
    setCurrentStep('selectReportType');
    setParagraphs([]);
    setAvailableFiles([]);
    setSelectedFiles({});
    setErrors({});
  };

  const handleFileSelectionChange = (
    section: string,
    fileName: string,
    isSelected: boolean
  ) => {
    setSelectedFiles((prev) => {
      const updatedSelection = { ...prev };
      if (isSelected) {
        updatedSelection[section] = [...(updatedSelection[section] || []), fileName];
      } else {
        updatedSelection[section] =
          updatedSelection[section]?.filter((file) => file !== fileName) || [];
      }
      return updatedSelection;
    });
  };

  // Validate that at least one file is selected for each section
  const validateSelections = () => {
    let isValid = true;
    const newErrors: { [section: string]: string } = {};

    paragraphs.forEach((paragraph) => {
      if (
        !selectedFiles[paragraph.section] ||
        selectedFiles[paragraph.section].length === 0
      ) {
        newErrors[paragraph.section] = `Please select at least one file for ${paragraph.section}.`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleGenerateDealWithValidation = () => {
    if (validateSelections()) {
      handleGenerateDeal(selectedFormat, selectedReportType, selectedFiles);
    }
  };

  const handleCloseModal = () => {
    setCurrentStep('selectReportType');
    setSelectedReportType('short');
    setSelectedFiles({});
    setErrors({});
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Select Files for Report Generation"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="relative w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Close Button */}
        <button
          onClick={handleCloseModal}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 rounded-full"
          aria-label="Close Modal"
        >
          &times;
        </button>

        <h2 className="text-xl font-semibold mb-4 text-center">Generate Report</h2>

        {currentStep === 'selectReportType' && (
          <div>
            <h3 className="font-medium mb-2">Select Report Type</h3>
            <div className="flex items-center mb-4">
              <label className="mr-4 flex items-center">
                <input
                  type="radio"
                  name="reportType"
                  value="short"
                  checked={selectedReportType === 'short'}
                  onChange={(e) =>
                    setSelectedReportType(e.target.value as 'short' | 'long')
                  }
                  className="form-radio"
                />
                <span className="ml-2">Short</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="reportType"
                  value="long"
                  checked={selectedReportType === 'long'}
                  onChange={(e) =>
                    setSelectedReportType(e.target.value as 'short' | 'long')
                  }
                  className="form-radio"
                />
                <span className="ml-2">Long</span>
              </label>
            </div>

            {/* Format Selection */}
            <div className="mb-4">
              <h3 className="font-medium mb-2">Select Report Format</h3>
              <div className="flex items-center">
                <label className="mr-4 flex items-center">
                  <input
                    type="radio"
                    name="reportFormat"
                    value="pdf"
                    checked={selectedFormat === 'pdf'}
                    onChange={(e) =>
                      setSelectedFormat(e.target.value as 'pdf' | 'word')
                    }
                    className="form-radio"
                  />
                  <span className="ml-2">PDF</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="reportFormat"
                    value="word"
                    checked={selectedFormat === 'word'}
                    onChange={(e) =>
                      setSelectedFormat(e.target.value as 'pdf' | 'word')
                    }
                    className="form-radio"
                  />
                  <span className="ml-2">Word</span>
                </label>
              </div>
            </div>

            {/* Next Button */}
            <button
              onClick={handleNext}
              className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Next
            </button>
          </div>
        )}

        {currentStep === 'selectFiles' && (
          <div>
            <button
              onClick={handleBack}
              className="mb-4 text-blue-500 hover:underline focus:outline-none"
            >
              &lt; Back
            </button>

            {isLoading ? (
              <div className="text-center">Loading files...</div>
            ) : (
              <>
                <div className="space-y-4 overflow-y-auto max-h-60">
                  {paragraphs.map((paragraph) => (
                    <div key={paragraph.section} className="mb-4">
                      <h3 className="font-medium">{paragraph.section}</h3>

                      {/* Dropdown for selecting files */}
                      <select
                        onChange={(e) =>
                          handleFileSelectionChange(
                            paragraph.section,
                            e.target.value,
                            true
                          )
                        }
                        className="block w-full mb-2 p-2 border border-gray-300 rounded"
                        defaultValue=""
                      >
                        <option value="">Select File</option>
                        {availableFiles.map((file) => (
                          <option key={file} value={file}>
                            {file}
                          </option>
                        ))}
                      </select>

                      {/* Display selected files as tags */}
                      <div className="flex flex-wrap gap-2 mb-2">
                        {selectedFiles[paragraph.section]?.map((file, index) => (
                          <span
                            key={index}
                            className="bg-gray-200 text-gray-800 font-semibold py-1 px-3 rounded-full flex items-center text-sm"
                          >
                            {file}
                            <button
                              onClick={() =>
                                handleFileSelectionChange(
                                  paragraph.section,
                                  file,
                                  false
                                )
                              }
                              className="ml-2 text-gray-600 font-bold"
                              aria-label={`Remove ${file}`}
                            >
                              &times;
                            </button>
                          </span>
                        ))}
                      </div>

                      {/* Error message */}
                      {errors[paragraph.section] && (
                        <p className="text-red-500 text-sm">
                          {errors[paragraph.section]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                {/* Generate Report Button */}
                <button
                  onClick={handleGenerateDealWithValidation}
                  className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Generate Report
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FileSelectionModal;
