// src\components\monitoring\Dashboard\DataTable.tsx
import React, { useCallback, useState } from 'react';
import EditableCell from '../../editableCell';
import { isFieldEditable } from '../../../utils/fieldUtils';
import { editableFieldPrefixes } from '../../../config/editableFields';
import { FinancialData } from '../../types/skeletonTypes';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface DataTableProps {
  reportDataList: FinancialData[];
  setReportDataList: React.Dispatch<React.SetStateAction<FinancialData[]>>;
  modifiedCells: Set<string>;
  setModifiedCells: React.Dispatch<React.SetStateAction<Set<string>>>;
  clientName: string;
  companyName: string;
}

const DataTable: React.FC<DataTableProps> = ({
  reportDataList,
  setReportDataList,
  modifiedCells,
  setModifiedCells,
  clientName,
  companyName,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleInputChange = useCallback(
    (reportDate: string, fieldPath: string[], value: number) => {
      const updatedData = reportDataList.map((doc) =>
        doc && doc.report_date === reportDate ? updateNestedField(doc, fieldPath, value) : doc
      );

      setReportDataList(updatedData);

      const cellKey = `${reportDate}-${fieldPath.join('.')}`;
      setModifiedCells((prev) => {
        const newSet = new Set(prev);
        newSet.delete(cellKey);
        return newSet;
      });
    },
    [reportDataList, setReportDataList, setModifiedCells]
  );

  const handleCellModify = useCallback(
    (reportDate: string, fieldPath: string[]) => {
      const cellKey = `${reportDate}-${fieldPath.join('.')}`;
      setModifiedCells((prev) => new Set(prev).add(cellKey));
    },
    [setModifiedCells]
  );

  /**
   * Determines whether a Group contains period-based data.
   * Returns true if it contains at least one period (month, quarter, etc.), false otherwise.
   */
  const isPeriodBasedGroup = (group: any): boolean => {
    if (typeof group !== 'object' || group === null) return false;
    const periods = ['month', 'quarter', 'ytd', 'ltm'];
    return periods.some((period) => period in group);
  };

  const renderNestedDataMultiColumn = (
    dataList: (any | undefined)[],
    reportDateList: string[],
    currentPath: string[] = [],
    level: number = 0
  ) => {
    if (dataList.length === 0 || dataList[0] === undefined) return null;

    const keys = Object.keys(dataList[0] || {});

    return keys.map((key, index) => {
      const padding = `${level * 20}px`;
      const newPath = [...currentPath, key];

      const currentDataList = dataList.map((data) => data?.[key]);

      // Check if currentDataList contains objects
      const isAllCurrentDataObjects = currentDataList.every(
        (data) => typeof data === 'object' && data !== null
      );

      if (isAllCurrentDataObjects) {
        // Now, check if the data contains 'Group'
        const allHaveGroup = currentDataList.every((data) => data && 'Group' in data);

        if (allHaveGroup) {
          // Now, check if the 'Group' contains period-based data
          const groupDataList = currentDataList.map((data) => data['Group']);
          const isPeriodBased = isPeriodBasedGroup(groupDataList[0]);

          if (isPeriodBased) {
            // Render period-based data
            return (
              <React.Fragment key={`${key}-${index}`}>
                <tr className="bg-gray-50">
                  <td
                    className="px-6 py-4 text-sm font-medium text-gray-900"
                    style={{ paddingLeft: padding }}
                  >
                    {key}
                  </td>
                  {reportDateList.map((_, idx) => (
                    <td key={idx} className="px-6 py-4"></td>
                  ))}
                </tr>
                {renderPeriodBasedData(groupDataList, reportDateList, [...newPath, 'Group'], level + 1)}
              </React.Fragment>
            );
          } else {
            // Render direct metrics
            return (
              <React.Fragment key={`${key}-${index}`}>
                <tr className="bg-gray-50">
                  <td
                    className="px-6 py-4 text-sm font-medium text-gray-900"
                    style={{ paddingLeft: padding }}
                  >
                    {key}
                  </td>
                  {reportDateList.map((_, idx) => (
                    <td key={idx} className="px-6 py-4"></td>
                  ))}
                </tr>
                {renderMetricsDirect(
                  groupDataList,
                  reportDateList,
                  [...newPath, 'Group'],
                  level + 1
                )}
              </React.Fragment>
            );
          }
        } else {
          // Current data is an object but does not have 'Group' key
          // Recursively render nested data
          return (
            <React.Fragment key={`${key}-${index}`}>
              <tr className="bg-gray-50">
                <td
                  className="px-6 py-4 text-sm font-medium text-gray-900"
                  style={{ paddingLeft: padding }}
                >
                  {key}
                </td>
                {reportDateList.map((_, idx) => (
                  <td key={idx} className="px-6 py-4"></td>
                ))}
              </tr>
              {renderNestedDataMultiColumn(currentDataList, reportDateList, newPath, level + 1)}
            </React.Fragment>
          );
        }
      } else {
        // Handle leaf nodes (non-object values)
        const editable = isFieldEditable(newPath, editableFieldPrefixes);

        return (
          <tr key={`${key}-${index}`} className="hover:bg-gray-100">
            <td
              className="px-6 py-4 text-sm font-medium text-gray-900"
              style={{ paddingLeft: padding }}
            >
              {key}
            </td>
            {dataList.map((data, idx) => {
              const rawValue = data?.[key];
              const displayValue =
                typeof rawValue === 'number' ? rawValue.toLocaleString() : rawValue || 'N/A';
              const reportDate = reportDateList[idx];
              const uniqueCellKey = `${reportDate}-${newPath.join('.')}`;
              const isModified = modifiedCells.has(uniqueCellKey);

              return (
                <td
                  key={idx}
                  className={classNames('px-6 py-4 whitespace-normal break-words text-sm', {
                    'bg-yellow-100': isModified,
                  })}
                  title={isModified ? 'Modified' : 'This field is not editable'}
                  style={{ maxWidth: '150px' }}
                >
                  {editable ? (
                    <EditableCell
                      value={rawValue !== undefined ? rawValue : ''}
                      onSave={(newValue) => handleInputChange(reportDate, newPath, newValue)}
                      onModify={() => handleCellModify(reportDate, newPath)}
                    />
                  ) : (
                    <div className="text-gray-700">{displayValue}</div>
                  )}
                </td>
              );
            })}
          </tr>
        );
      }
    });
  };

  const renderPeriodBasedData = (
    groupDataList: any[],
    reportDateList: string[],
    currentPath: string[],
    level: number
  ) => {
    const periods = ['month', 'quarter', 'ytd', 'ltm'];

    return periods.map((period) => {
      const padding = `${level * 20}px`;
      const newPath = [...currentPath, period];

      const periodDataList = groupDataList.map((groupData) => groupData?.[period]);

      if (periodDataList.every((data) => data !== undefined)) {
        return (
          <React.Fragment key={period}>
            <tr className="bg-gray-50">
              <td
                className="px-6 py-4 text-sm font-medium text-gray-900"
                style={{ paddingLeft: padding }}
              >
                {period}
              </td>
              {reportDateList.map((_, idx) => (
                <td key={idx} className="px-6 py-4"></td>
              ))}
            </tr>
            {renderMetrics(periodDataList, reportDateList, newPath, level + 1)}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const renderMetrics = (
    metricsDataList: any[],
    reportDateList: string[],
    currentPath: string[],
    level: number
  ) => {
    const metrics = ['actual', 'budget', 'priorYear'];

    return metrics.map((metric) => {
      const padding = `${level * 20}px`;
      const newPath = [...currentPath, metric];

      return (
        <tr key={metric} className="hover:bg-gray-100">
          <td
            className="px-6 py-4 text-sm font-medium text-gray-900"
            style={{ paddingLeft: padding }}
          >
            {metric}
          </td>
          {metricsDataList.map((metricsData, idx) => {
            const rawValue = metricsData?.[metric];
            const displayValue =
              typeof rawValue === 'number' ? rawValue.toLocaleString() : rawValue || 'N/A';
            const reportDate = reportDateList[idx];
            const uniqueCellKey = `${reportDate}-${newPath.join('.')}`;
            const isModified = modifiedCells.has(uniqueCellKey);

            const editable = isFieldEditable(newPath, editableFieldPrefixes);

            return (
              <td
                key={idx}
                className={classNames('px-6 py-4 whitespace-normal break-words text-sm', {
                  'bg-yellow-100': isModified,
                })}
                title={isModified ? 'Modified' : 'This field is not editable'}
                style={{ maxWidth: '150px' }}
              >
                {editable ? (
                  <EditableCell
                    value={rawValue !== undefined ? rawValue : ''}
                    onSave={(newValue) => handleInputChange(reportDate, newPath, newValue)}
                    onModify={() => handleCellModify(reportDate, newPath)}
                  />
                ) : (
                  <div className="text-gray-700">{displayValue}</div>
                )}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const renderMetricsDirect = (
    groupDataList: any[],
    reportDateList: string[],
    currentPath: string[],
    level: number
  ) => {
    const metrics = ['actual', 'budget', 'priorYear'];

    return metrics.map((metric) => {
      const padding = `${level * 20}px`;
      const newPath = [...currentPath, metric];

      return (
        <tr key={metric} className="hover:bg-gray-100">
          <td
            className="px-6 py-4 text-sm font-medium text-gray-900"
            style={{ paddingLeft: padding }}
          >
            {metric}
          </td>
          {groupDataList.map((groupData, idx) => {
            const rawValue = groupData?.[metric];
            const displayValue =
              typeof rawValue === 'number' ? rawValue.toLocaleString() : rawValue || 'N/A';
            const reportDate = reportDateList[idx];
            const uniqueCellKey = `${reportDate}-${newPath.join('.')}`;
            const isModified = modifiedCells.has(uniqueCellKey);

            const editable = isFieldEditable(newPath, editableFieldPrefixes);

            return (
              <td
                key={idx}
                className={classNames('px-6 py-4 whitespace-normal break-words text-sm', {
                  'bg-yellow-100': isModified,
                })}
                title={isModified ? 'Modified' : 'This field is not editable'}
                style={{ maxWidth: '150px' }}
              >
                {editable ? (
                  <EditableCell
                    value={rawValue !== undefined ? rawValue : ''}
                    onSave={(newValue) => handleInputChange(reportDate, newPath, newValue)}
                    onModify={() => handleCellModify(reportDate, newPath)}
                  />
                ) : (
                  <div className="text-gray-700">{displayValue}</div>
                )}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const reportDateList: string[] = reportDataList
    .map((doc) => doc?.report_date)
    .filter((date): date is string => date !== undefined);

  return (
    <div className="bg-white shadow rounded-lg p-6 mt-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-semibold">Documents (Structured View for Multiple Months)</h3>
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="flex items-center text-blue-500 hover:text-blue-700"
        >
          {isCollapsed ? 'Expand' : 'Collapse'}
          {isCollapsed ? (
            <ChevronDownIcon className="w-5 h-5 ml-2" />
          ) : (
            <ChevronUpIcon className="w-5 h-5 ml-2" />
          )}
        </button>
      </div>

      {!isCollapsed && (
        <div className="w-full overflow-x-auto max-w-full transition-all duration-300 ease-in-out">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[150px]"
                >
                  Field
                </th>
                {reportDateList.map((reportDate, idx) => (
                  <th
                    key={idx}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[150px]"
                  >
                    {reportDate}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {renderNestedDataMultiColumn(reportDataList, reportDateList)}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

// Helper function to update nested fields
const updateNestedField = (data: any, fieldPath: string[], value: any): any => {
  if (fieldPath.length === 1) {
    return { ...data, [fieldPath[0]]: value };
  }
  const [first, ...rest] = fieldPath;
  return {
    ...data,
    [first]: data[first]
      ? updateNestedField(data[first], rest, value)
      : updateNestedField({}, rest, value),
  };
};

export default DataTable;
