// src/components/login/login.tsx
import React, { useEffect } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { route, user } = useAuthenticator((context) => [context.route, context.user]);

  // Redirect to /newproject after successful login
  useEffect(() => {
    if (route === 'authenticated') {
      navigate('/newproject');  // Redirect to the New Project page
    }
  }, [route, navigate]);

  return (
    <div className="flex justify-center items-center h-screen font-inter">
      <Authenticator>
        {({ signOut }) => (
          <div className="flex flex-col gap-2 p-4 border border-gray-400 rounded w-1/3">
            <h1 className="text-black font-semibold">
              Welcome {user?.username} 
            </h1>
            <button
              className="bg-black text-white px-3 py-2 rounded-2xl"
              onClick={signOut}
            >
              Sign Out
            </button>
          </div>
        )}
      </Authenticator>
    </div>
  );
};

export default Login;
