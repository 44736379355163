// src/components/legal/CompareSFAs.tsx

import React, { useEffect, useState } from 'react';
import { apiCall } from '../../utils/api';
import Spinner from '../../images/DarkSpinner.gif';
import ExcelIcon from '../../images/excel-icon.svg'; // Ensure you have this icon in the specified path
import DataTableCompareSFAs from './DataTableCompareSFAs';
import ToastMessage from '../ui/toastMessage/toast-message';

interface SFA_Summary {
  _id: string;
  company_name: string;
  filename: string;
  type: string;
  // Add other fields as per your schema
  [key: string]: any;
}

interface CompareSFAsProps {
  clientName: string;
  onClose: () => void;
  setToast: React.Dispatch<React.SetStateAction<boolean>>;
  setToastMsg: React.Dispatch<React.SetStateAction<string>>;
  setToastStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompareSFAs: React.FC<CompareSFAsProps> = ({
  clientName,
  onClose, // Not used internally, navigation handled by legal-main.tsx
  setToast,
  setToastMsg,
  setToastStatus,
}) => {
  const [sfaSummaries, setSfaSummaries] = useState<SFA_Summary[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modifiedCells, setModifiedCells] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchSFASummaries = async () => {
      setLoading(true);
      try {
        const response = await apiCall(`/fetch-sfas/${encodeURIComponent(clientName)}`, {
          method: 'GET',
        });
        setSfaSummaries(response);
      } catch (error) {
        console.error('Failed to fetch SFA_Summary documents:', error);
        setToastMsg('Failed to fetch SFA summaries.');
        setToastStatus(false);
        setToast(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSFASummaries();
  }, [clientName, setToast, setToastMsg, setToastStatus]);

  /**
   * Handle saving changes back to MongoDB
   */
  const handleSave = async (filename: string, fieldPath: string, newValue: string) => {
    try {
      await apiCall(`/update-sfa/${encodeURIComponent(clientName)}/${encodeURIComponent(filename)}/${encodeURIComponent(fieldPath)}`, {
        method: 'PUT',
        data: { newValue },
      });
      setToastMsg('Field updated successfully.');
      setToastStatus(true);
      setToast(true);
    } catch (error) {
      console.error('Failed to update field:', error);
      setToastMsg('Failed to update field.');
      setToastStatus(false);
      setToast(true);
    }
  };

  /**
   * Handle cell modification
   */
  const handleCellModify = (filename: string, fieldPath: string) => {
    const cellKey = `${filename}-${fieldPath}`;
    setModifiedCells((prev) => new Set(prev).add(cellKey));
  };

  /**
   * Handle exporting the table to Excel
   * (Functionality to be implemented on another day)
   */
  const handleExportToExcel = () => {
    // Placeholder for Excel export functionality
    setToastMsg('Excel export functionality to be implemented.');
    setToastStatus(false);
    setToast(true);
  };

  return (
    <div className="p-4">
      <div className="flex justify-end items-center mb-4">
        {/* Excel Export Button: Icon Only */}
        <button
          onClick={handleExportToExcel}
          className="p-2 rounded hover:bg-gray-100"
          title="Export to Excel"
        >
          <img src={ExcelIcon} alt="Export to Excel" className="w-5 h-5" />
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <img src={Spinner} alt="Loading..." className="w-12 h-12" />
        </div>
      ) : (
        sfaSummaries.length > 0 ? (
          <DataTableCompareSFAs
            sfaSummaries={sfaSummaries}
            handleSave={handleSave}
            modifiedCells={modifiedCells}
            handleCellModify={handleCellModify}
          />
        ) : (
          <div className="text-center text-red-500">No SFA_Summary documents found.</div>
        )
      )}
    </div>
  );
};

export default CompareSFAs;
