// src/components/ui/dropZone/dropZone.tsx

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import upload from '../../../images/Upload_File.svg';

interface DropZoneProps {
  file: React.Dispatch<React.SetStateAction<File[]>>;
  disabled?: boolean;
}

const DropZone: React.FC<DropZoneProps> = ({ file, disabled }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled) {
        return;
      }
      file((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    [file, disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    noClick: true, // Prevent the entire area from being clickable
  });

  return (
    <div
      {...getRootProps()}
      className={`bg-gray-50 border-2 border-dashed ${
        isDragActive ? 'border-blue-500' : 'border-gray-300'
      } py-8 w-full rounded-3xl flex flex-col items-center`}
    >
      <input {...getInputProps()} />
      <div className="text-center flex flex-col gap-4">
        <div className="flex flex-col justify-center items-center">
          <img src={upload} className="p-4" alt="Upload Icon" />
          <p className="text-black text-lg">Select a file or drag and drop here</p>
          <p className="text-gray-500 text-sm">PDF Format Only</p>
        </div>
        <div className="flex justify-center">
          <label htmlFor="file-upload" className="cursor-pointer">
            <span
              className="py-2 px-6 border border-gray-300 text-gray-700 rounded-xl hover:bg-gray-100"
              onClick={(e) => e.stopPropagation()} // Prevent triggering the dropzone click
            >
              SELECT FILE
            </span>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              multiple
              accept=".pdf"
              className="sr-only"
              onChange={(e) => {
                if (e.target.files && !disabled) {
                  file((prevFiles) => [...prevFiles, ...Array.from(e.target.files!)]);
                }
              }}
              disabled={disabled}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
