// src\components\monitoring\update-monitoring-modal.tsx
import React, { useState } from 'react';
import { apiCall } from '../../utils/api.ts';

interface UpdateMonitoringModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;
  companyName: string;
  nextReportDate: string; // Pass next expected report date for validation
}

const UpdateMonitoringModal: React.FC<UpdateMonitoringModalProps> = ({
  setShowModal,
  clientName,
  companyName,
  nextReportDate,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedPLPeriods, setSelectedPLPeriods] = useState<string[]>([]);
  const [selectedCFPeriods, setSelectedCFPeriods] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    setFile(selectedFile || null);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };

  const handlePeriodSelect = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
    const period = e.target.value;
    if (type === 'ProfitAndLoss' && !selectedPLPeriods.includes(period)) {
      setSelectedPLPeriods([...selectedPLPeriods, period]);
    } else if (type === 'CashFlow' && !selectedCFPeriods.includes(period)) {
      setSelectedCFPeriods([...selectedCFPeriods, period]);
    }
    e.target.value = '';
  };

  const removePeriod = (period: string, type: string) => {
    if (type === 'ProfitAndLoss') {
      setSelectedPLPeriods(selectedPLPeriods.filter(p => p !== period));
    } else if (type === 'CashFlow') {
      setSelectedCFPeriods(selectedCFPeriods.filter(p => p !== period));
    }
  };

  const handleSubmit = async () => {
    // Construct the tags object
    const tags = {
      ProfitAndLoss: selectedPLPeriods,
      CashFlow: selectedCFPeriods,
    };

    if (!file || !selectedDate) {
      alert('Please fill all fields and select a file');
      return;
    }

    if (selectedDate !== nextReportDate) {
      alert(`The selected report date (${selectedDate}) does not match the expected next report date (${nextReportDate}).`);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('clientName', clientName);
      formData.append('companyName', companyName);
      formData.append('pdfFile', file);
      formData.append('pdfName', file.name);
      formData.append('reportDate', selectedDate);
      formData.append('tags', JSON.stringify(tags));

      const response = await apiCall('/update-monitoring', {
        method: 'POST',
        data: formData,  // Use 'data' instead of 'body'
        // headers: {
        //   'Content-Type': 'multipart/form-data',  // Ensure correct headers
        // },
      });
  
      const result = response;  // Access data directly
  
      if (response.status === 200 && result.message === 'Monitoring updated and calculations done') {
        alert('Monitoring updated successfully');
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      alert('Failed to update monitoring');
      console.error('Error updating monitoring:', error);
    }
  
    setShowModal(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/2">
        <h2 className="text-lg font-semibold mb-4">Update Monitoring Report</h2>

        <label htmlFor="fileUpload">Upload Report</label>
        <input
          id="fileUpload"
          type="file"
          onChange={handleFileChange}
          accept=".pdf"
          className="block w-full mb-4"
        />

        <label htmlFor="reportDate">Report Date</label>
        <input
          id="reportDate"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="block w-full mb-4"
        />

        <div className="flex justify-end">
          <button onClick={handleSubmit} className="bg-blue-600 text-white px-4 py-2 rounded">
            Submit
          </button>
          <button onClick={() => setShowModal(false)} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateMonitoringModal;