// src\components\editableCell.tsx

import React, { useState, useEffect } from 'react';

interface EditableCellProps {
  value: string | number;
  onSave: (value: number) => void; // Changed to accept only numbers
  onModify: () => void; // New prop to notify modifications
}

const EditableCell: React.FC<EditableCellProps> = ({ value, onSave, onModify }) => {
  const [inputValue, setInputValue] = useState<string>(value.toString());
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const parsedValue = parseFloat(inputValue);
      if (isNaN(parsedValue)) {
        setError('Please enter a valid number.');
        return;
      }
      onSave(parsedValue);
      setIsEditing(false);
      setError(null);
    } else if (e.key === 'Escape') {
      setInputValue(value.toString()); // Revert changes
      setIsEditing(false);
      setError(null);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onModify(); // Notify parent that this cell has been modified
    // Optional: Immediate validation
    if (newValue === '' || isNaN(Number(newValue))) {
      setError('Invalid number');
    } else {
      setError(null);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputValue === '' || isNaN(Number(inputValue))) {
      setInputValue(value.toString()); // Revert changes if invalid
      setError(null);
    }
  };

  return (
    <div>
      <input
        type="number" // Changed to number type
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsEditing(true)}
        onBlur={handleBlur}
        step="any" // Allows decimals
        style={{
          width: '100%',
          borderBottom: '1px solid #ccc',
          textAlign: 'center',
          backgroundColor: isEditing ? '#ffffe0' : 'transparent', // Light yellow when editing
        }}
        title="Editable Field"
      />
      {error && <span className="text-red-500 text-sm">{error}</span>}
    </div>
  );
};

export default EditableCell;
