// src/components/ui/ImageModal.tsx

import React, { useState, useEffect, useRef } from 'react';
import { FaSearchPlus } from 'react-icons/fa'; // FontAwesome lens icon

interface ImageModalProps {
  imageUrl: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, onClose }) => {
  const [zoom, setZoom] = useState(1); // State to manage zoom level
  const [hover, setHover] = useState(false); // State to manage hover effect
  const [isDragging, setIsDragging] = useState(false); // State to manage dragging
  const [position, setPosition] = useState({ x: 0, y: 0 }); // State to manage image position
  const [startPos, setStartPos] = useState<{ x: number; y: number } | null>(null); // Start position for dragging

  const imageRef = useRef<HTMLImageElement>(null); // Ref to the image element

  const handleZoomToggle = () => {
    if (zoom === 1) {
      setZoom(1.5); // Zoom in to 2x
    } else {
      setZoom(1); // Zoom out to 1x
      setPosition({ x: 0, y: 0 }); // Reset position when zooming out
    }
  };

  // Start dragging
  const handleMouseDown = (e: React.MouseEvent) => {
    if (zoom > 1) {
      setIsDragging(true);
      setStartPos({ x: e.clientX - position.x, y: e.clientY - position.y }); // Set starting position for drag
    }
  };

  // Handle dragging movement
  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging && startPos && imageRef.current) {
      const imageWidth = imageRef.current.offsetWidth * zoom;
      const imageHeight = imageRef.current.offsetHeight * zoom;
      const containerWidth = imageRef.current.parentElement?.offsetWidth || 0;
      const containerHeight = imageRef.current.parentElement?.offsetHeight || 0;

      // Calculate boundaries based on container and image size
      const maxX = Math.max((imageWidth - containerWidth) / 2, 0);
      const maxY = Math.max((imageHeight - containerHeight) / 2, 0);

      // Calculate new position with boundaries
      const newX = e.clientX - startPos.x;
      const newY = e.clientY - startPos.y;

      setPosition({
        x: Math.max(-maxX, Math.min(maxX, newX)),
        y: Math.max(-maxY, Math.min(maxY, newY)),
      });
    }
  };

  // Stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
    setStartPos(null); // Reset start position
  };

  // Attach and detach global mouse event listeners
  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startPos]); // Re-run effect if dragging state changes

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 cursor-pointer"
      onClick={onClose}
    >
      {/* Modal Content that adapts to the image size */}
      <div
        className="relative p-4 bg-white rounded shadow-lg cursor-auto overflow-hidden"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={() => setHover(true)} // Show lens icon on hover
        onMouseLeave={() => setHover(false)} // Hide lens icon when not hovering
        onMouseDown={handleMouseDown} // Start dragging
      >
        {/* Image with zoom and pan applied */}
        <img
          ref={imageRef} // Reference to the image
          src={imageUrl}
          alt="Modal"
          className="rounded cursor-move"
          style={{
            transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? 'none' : 'transform 0.3s', // Smooth zoom transition when not dragging
            maxWidth: '100%',
            maxHeight: '90vh',
            cursor: zoom > 1 ? 'grab' : 'zoom-in', // Cursor changes depending on zoom level
          }}
          onClick={handleZoomToggle} // Toggle zoom on image click
        />

        {/* Lens Icon for zoom */}
        {hover && zoom === 1 && (
          <FaSearchPlus
            className="absolute text-white text-3xl cursor-pointer"
            style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            onClick={handleZoomToggle} // Toggle zoom on lens click
          />
        )}
      </div>
    </div>
  );
};

export default ImageModal;
