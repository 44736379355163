// src/pages/help.tsx
import React from 'react';
import Layout from '../components/ui/layout/layout';
import HelpMain from '../components/help/help-main';

const Help: React.FC = () => {
  return (
    <Layout>
      <HelpMain />
    </Layout>
  );
};

export default Help;
