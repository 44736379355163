// src/components/monitoring/charts/LineChartComponent.tsx
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface LineConfig {
  dataKey: string;
  stroke: string;
  name: string;
}

interface LineChartComponentProps {
  data: { name: string; [key: string]: number | string }[];
  lines: LineConfig[];
  height?: number;
  width?: string | number;
  margin?: { top: number; right: number; left: number; bottom: number };
}

const formatYAxis = (value: number): string => {
  if (value >= 1_000_000_000) return `${(value / 1_000_000_000).toFixed(1)}B`;
  if (value >= 1_000_000) return `${(value / 1_000_000).toFixed(1)}M`;
  if (value >= 1_000) return `${(value / 1_000).toFixed(1)}K`;
  return value.toString();
};

const LineChartComponent: React.FC<LineChartComponentProps> = ({
  data,
  lines,
  height = 200,
  width = '90%',
  margin = { top: 20, right: 30, left: 20, bottom: 5 },
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis tickFormatter={formatYAxis} tick={{ fontSize: 12 }} />
        <Tooltip formatter={(value: any) => formatYAxis(Number(value))} />
        <Legend wrapperStyle={{ fontSize: 12 }} />
        {lines.map((line) => (
          <Line
            key={line.dataKey}
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.stroke}
            name={line.name}
            activeDot={{ r: 6 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
