// src\pages\monitoringPage.tsx
import React from 'react';
import Layout from '../components/ui/layout/layout';
import MonitoringMain from '../components/monitoring/monitoring-main';

const MonitoringPage = () => {
  return (
    <Layout>
      <MonitoringMain />
    </Layout>
  );
};

export default MonitoringPage;
