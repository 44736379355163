// src/components/legal/EditableCellLegal.tsx

import React, { useState, useEffect } from 'react';

interface EditableCellLegalProps {
  value: string;
  onSave: (value: string) => void;
  onModify: () => void;
}

const EditableCellLegal: React.FC<EditableCellLegalProps> = ({ value, onSave, onModify }) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Updated event types to HTMLTextAreaElement
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) { // Allow Shift+Enter for new lines
      e.preventDefault(); // Prevent adding a new line
      if (inputValue.trim() === '') {
        setError('Please enter a valid string.');
        return;
      }
      onSave(inputValue.trim());
      setIsEditing(false);
      setError(null);
    } else if (e.key === 'Escape') {
      setInputValue(value); // Revert changes
      setIsEditing(false);
      setError(null);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onModify(); // Notify parent that this cell has been modified
    // Optional: Immediate validation
    if (newValue.trim() === '') {
      setError('Invalid input');
    } else {
      setError(null);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputValue.trim() === '') {
      setInputValue(value); // Revert changes if invalid
      setError(null);
    }
  };

  return (
    <div className="relative">
      <textarea
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsEditing(true)}
        onBlur={handleBlur}
        className="w-full px-2 py-1 border-none focus:outline-none resize-none bg-transparent"
        style={{
          minHeight: '40px',
          maxHeight: '100px',
          overflowY: 'auto',
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
        title={inputValue} // Show full text on hover
      />
      {error && <span className="absolute top-full left-0 text-red-500 text-xs">{error}</span>}
    </div>
  );
};

export default EditableCellLegal;
