import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastMessageProps {
  toastMsg: string;
  isSuccess: boolean;
  setToast: Dispatch<SetStateAction<boolean>>;
}

const ToastMessage: React.FC<ToastMessageProps> = ({ toastMsg, isSuccess, setToast }) => {
  useEffect(() => {
    setTimeout(() => {
      setToast(false);
    }, 3000);
    let toastId: string | number;
    if (isSuccess) {
      toastId = toast.success(toastMsg, { autoClose: 3000, toastId: 'success1' });
    } else {
      toastId = toast.error(toastMsg, { autoClose: 3000, toastId: 'failure1' });
    }
  }, [toastMsg]);

  return (
    <div className='fixed right-0 top-0 z-[1002]'>
      <ToastContainer />
    </div>
  );
};

export default ToastMessage;
