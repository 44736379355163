// src/components/types/skeletonTypes.ts
import { z } from 'zod';

// Define a flexible number field that accepts numbers as double, int32, or string format for Decimal128
const flexibleNumberField = z.union([
  z.number(),
  z.string().regex(/^\d+(\.\d+)?$/, "Expected Decimal128 format as string"), // Accepts numbers in string format if needed
]);

// Schema for Group objects containing period-based data
const periodMetricsSchema = z.object({
  month: z.object({
    actual: flexibleNumberField.optional(),
    budget: flexibleNumberField.optional(),
    priorYear: flexibleNumberField.optional(),
  }).optional(),
  quarter: z.object({
    actual: flexibleNumberField.optional(),
    budget: flexibleNumberField.optional(),
    priorYear: flexibleNumberField.optional(),
  }).optional(),
  ytd: z.object({
    actual: flexibleNumberField.optional(),
    budget: flexibleNumberField.optional(),
    priorYear: flexibleNumberField.optional(),
  }).optional(),
  ltm: z.object({
    actual: flexibleNumberField.optional(),
    budget: flexibleNumberField.optional(),
    priorYear: flexibleNumberField.optional(),
  }).optional(),
});

// Schema for Group objects containing direct values without periods
const directGroupMetricsSchema = z.object({
  actual: flexibleNumberField.optional(),
  budget: flexibleNumberField.optional(),
  priorYear: flexibleNumberField.optional(),
});

// Reusable schemas
const groupMetricsWithPeriodsSchema = z.object({
  Group: periodMetricsSchema.optional(),
});

const groupMetricsDirectSchema = z.object({
  Group: directGroupMetricsSchema.optional(),
});

// Define schemas for each section
const profitAndLossSchema = z.object({
  Revenue: groupMetricsWithPeriodsSchema.optional(),
  GrossProfit: groupMetricsWithPeriodsSchema.optional(),
  GrossMarginPercentage: groupMetricsWithPeriodsSchema.optional(),
  EBITDA: groupMetricsWithPeriodsSchema.optional(),
  EBITDAMarginPercentage: groupMetricsWithPeriodsSchema.optional(),
}).optional();

const cashFlowSchema = z.object({
  FreeCashFlow: z.object({
    FreeCashFlow: groupMetricsWithPeriodsSchema.optional(),
    ChangesNetWorkingCapital: groupMetricsWithPeriodsSchema.optional(),
    IncomeTax: groupMetricsWithPeriodsSchema.optional(),
    Capex: groupMetricsWithPeriodsSchema.optional(),
    DSCRRatio: groupMetricsDirectSchema.optional(),
  }).optional(),
  WorkingCapital: z.object({
    ChangeInInventory: groupMetricsWithPeriodsSchema.optional(),
    ChangeInTradeReceivables: groupMetricsWithPeriodsSchema.optional(),
    ChangeInTradePayables: groupMetricsWithPeriodsSchema.optional(),
    ChangeInOtherAssets: groupMetricsWithPeriodsSchema.optional(),
    ChangeInOtherLiabilities: groupMetricsWithPeriodsSchema.optional(),
  }).optional(),
  OperatingActivities: z.object({
    CashFlowFromOperatingActivities: groupMetricsWithPeriodsSchema.optional(),
    InterestPaid: groupMetricsWithPeriodsSchema.optional(),
  }).optional(),
}).optional();

// LeverageBreakdown schema with direct Group metrics
const leverageBreakdownSchema = z.object({
  SFAAdjEBITDA: groupMetricsDirectSchema.optional(),
  GrossDebt: z.object({
    Group: directGroupMetricsSchema.optional(),
    BreakdownByInstrument: z.object({
      RCF: groupMetricsDirectSchema.optional(),
      DrawnRCF: groupMetricsDirectSchema.optional(),
      UndrawnRCF: groupMetricsDirectSchema.optional(),
      TLB: groupMetricsDirectSchema.optional(),
      "TLBAdd-on": groupMetricsDirectSchema.optional(),
      TLB1: groupMetricsDirectSchema.optional(),
      TLB2: groupMetricsDirectSchema.optional(),
    }).optional(),
  }).optional(),
  CashAvailable: groupMetricsDirectSchema.optional(),
  NetDebt: groupMetricsDirectSchema.optional(),
  LeverageRatio: groupMetricsDirectSchema.optional(),
}).optional();

// Define the complete schema for the document
export const financialDataSchema = z.object({
  _id: z.union([
    z.string().regex(/^[a-f\d]{24}$/i), // Matches a 24-character hexadecimal string (ObjectId format)
    z.undefined(),
  ]).optional(),
  company_name: z.string().optional(),
  created_at: z.object({}).optional(),
  report_date: z.string().optional(),
  report_frequency: z.string().optional(),
  reported_periods: z.object({
    ProfitAndLoss: z.array(z.string()).optional(),
    CashFlow: z.array(z.string()).optional(),
  }).optional(),
  year_end: z.string().optional(),
  year_start: z.string().optional(),
  last_quarter_end: z.string().optional(),
  next_quarter_end: z.string().optional(),
  nextreport_date: z.string().optional(),
  performance_summary: z.string().optional(),
  reportingcurrency: z.string().optional(),
  ProfitAndLoss: profitAndLossSchema.optional(),
  CashFlow: cashFlowSchema.optional(),
  LeverageBreakdown: leverageBreakdownSchema.optional(),
}).optional();

// Infer the TypeScript type from the Zod schema
export type FinancialData = z.infer<typeof financialDataSchema>;
