// src/pages/dataExtractionPage.tsx

import React from 'react';
import Layout from '../components/ui/layout/layout';
import DataExtractionMain from '../components/data-extraction/DataExtractionMain';

const DataExtractionPage = () => {
  return (
    <Layout>
      <DataExtractionMain />
    </Layout>
  );
};

export default DataExtractionPage;
