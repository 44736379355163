// src\components\monitoring\Dashboard\SaveButton.tsx
import React from 'react';
import { FinancialData } from '../../types/skeletonTypes';
import { apiCall } from '../../../utils/api';

interface SaveButtonProps {
  modifiedCells: Set<string>;
  reportDataList: FinancialData[];
  clientName: string;
  companyName: string;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  modifiedCells,
  reportDataList,
  clientName,
  companyName,
  setError,
  setSuccessMessage,
  setLoading,
}) => {
  const handleSaveData = async () => {
    console.log('Client Name:', clientName);
    console.log('Company Name:', companyName);
    console.log('Report Data List:', reportDataList);
    console.log('Modified Cells:', modifiedCells);
    
    if (modifiedCells.size > 0) {
      alert('Please press Enter in all modified cells to commit your changes before saving.');
      return;
    }

    try {
      setLoading(true);

      const updates = reportDataList.reduce((acc, doc) => {
        if (doc?.report_date) { // Ensure report_date exists
          acc[doc?.report_date] = doc;
        } else {
          console.warn('Document missing report_date:', doc);
        }
        return acc;
      }, {} as { [reportDate: string]: FinancialData });

      console.log('Prepared Updates:', updates);

      const response = await apiCall('/update-company-documents', {
        method: 'POST',
        data: {
          collectionName: clientName,
          companyName,
          updates,
        },
      });

      console.log('API Response:', response);
      setSuccessMessage('Data saved successfully.');
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-end mt-4">
      <button
        onClick={handleSaveData}
        className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${
          modifiedCells.size > 0
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-green-600 hover:bg-green-700'
        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
        disabled={modifiedCells.size > 0}
      >
        Save Changes
      </button>
      {modifiedCells.size > 0 && (
        <p className="text-red-500 text-sm mt-2">
          Please press Enter in all modified cells to commit your changes before saving.
        </p>
      )}
    </div>
  );
};

export default SaveButton;
