import React from 'react';
import Layout from '../components/ui/layout/layout';

const Setting = () => {
  return (
    <Layout>
      <div>coming soon</div>;
    </Layout>
  );
};

export default Setting;
