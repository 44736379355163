// src/utils/api.ts
import axios from 'axios';
import { getAccessToken } from './authUtils';
import { getAppConfig } from '../config/get-app-config.ts';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || ''; // Base URL for your API


const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true, // Allow sending of credentials if necessary
  timeout: 600000,  // 10 minutes
});

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(async (config) => {
  try {
    const token = await getAccessToken(); // Fetch the access token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn('No access token found for request.');
    }
  } catch (error) {
    console.error('Failed to fetch JWT token:', error);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

/**
 * Centralized API call function that handles both JSON and FormData requests.
 *
 * @param {string} endpoint - The API endpoint to call.
 * @param {object} options - Axios options including method, data, headers, responseType, and params.
 * @returns {Promise<any>} - Returns the API response data or full response for 'blob' responseType.
 */
export const apiCall = async (endpoint: string, options: any = {}) => {
  try {
    const { method = 'GET', data, headers = {}, responseType = 'json', params } = options;

    const isFormData = data instanceof FormData;

    const mergedHeaders = {
      ...headers,
      ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
    };

    console.log(`Making API call to ${endpoint}, method: ${method}, headers:`, mergedHeaders);

    const response = await axiosInstance({
      url: endpoint,
      method,
      data,
      headers: mergedHeaders,
      responseType,
      params, // Include query parameters
    });

    if (responseType === 'blob') {
      return response;
    }

    return response.data;
  } catch (error: any) {
    console.error('API call error:', error);

    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }

    throw error.response?.data || error;
  }
};



export default axiosInstance;
