// client/src/config/editableFields.ts
export const editableFieldPrefixes: string[] = [
  'ProfitAndLoss',
  'CashFlow.FreeCashFlow',
  'CashFlow.FreeCashFlow.FreeCashFlow',
  'CashFlow.FreeCashFlow.ChangesNetWorkingCapital',
  'CashFlow.FreeCashFlow.IncomeTax',
  'CashFlow.FreeCashFlow.Capex',
  'CashFlow.FreeCashFlow.DSCRRatio',
  'LeverageBreakdown',
  // Add more specific prefixes as needed
];
