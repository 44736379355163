// src\components\ui\layout\layout.tsx
import React, { useState, useEffect } from 'react';
import NavBar from '../navbar/navBar';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const handleExpandSidebar = () => {
    setIsCollapsed(false);
  };

  const handleCollapseSidebar = () => {
    setIsCollapsed(true);
  };

  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.innerWidth < 768;
      setIsSmallScreen(isSmall);
      if (isSmall) {
        setIsCollapsed(true); // Automatically collapse the navbar on small screens
      } else {
        setIsCollapsed(false); // Automatically expand the navbar on large screens
      }
    };

    handleResize(); // Set the initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContentClick = () => {
    if (isSmallScreen && !isCollapsed) {
      setIsCollapsed(true);
    }
  };

  return (
    <div className="relative flex w-full h-full overflow-hidden">
      <NavBar
        isCollapsed={isCollapsed}
        onExpandSidebar={handleExpandSidebar}
        onCollapseSidebar={handleCollapseSidebar}
        isSmallScreen={isSmallScreen}
      />
      <div
        className="flex-grow h-full transition-all duration-300"
        style={{
          marginLeft: isCollapsed ? '0' : isSmallScreen ? '0' : '0rem',
        }}
        onClick={handleContentClick}
      >
        <div className="w-full h-full flex flex-col">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
