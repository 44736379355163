import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import delete_icon from '../../images/delete-bin.svg';
import ReportIcon from '../../images/report.svg';
import ToastMessage from '../ui/toastMessage/toast-message';
import Spinner from '../../images/DarkSpinner.gif';
import FileSelectionModal from '../deal analysis/file-selection-modal'; // Adjust the import path
import { apiCall } from '../../utils/api';

interface DealTableProps {
  data: string[];
  setData: React.Dispatch<React.SetStateAction<string[]>>;
  setHeader: React.Dispatch<React.SetStateAction<string>>;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  clientName: string;
}

const DealTable: React.FC<DealTableProps> = ({
  data,
  setData,
  setHeader,
  companyName,
  setCompanyName,
  clientName,
}) => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);
  const [generatingReports, setGeneratingReports] = useState<{ [key: string]: boolean }>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDealName, setSelectedDealName] = useState<string>('');

  const closeModal = () => setIsModalOpen(false);

  const deleteDeal = async (name: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setShowToast(true);
      return;
    }

    // Confirmation dialog
    if (window.confirm(`Are you sure you want to delete the deal: ${name}?`)) {
      try {
        // Use the updated API endpoint to delete documents by company name
        const response = await apiCall(`/collection/${clientName}/company/${name}`, {
          method: 'DELETE',
        });
        if (response.status === 200) {
          const newData = data.filter((item) => item !== name);
          setData(newData);
          setToastMsg('Deal deleted successfully');
          setToastStatus(true);
          setShowToast(true);
        }
      } catch (error) {
        setToastMsg('Something went wrong');
        setToastStatus(false);
        setShowToast(true);
      }
    }
  };

  const generateReport = (name: string) => {
    if (!clientName) {
      setToastMsg('Client name is not available');
      setToastStatus(false);
      setShowToast(true);
      return;
    }

    setSelectedDealName(name);
    setIsModalOpen(true);
  };

  const handleGenerateDeal = async (
    format: string,
    reportType: string,
    selectedFiles: { [section: string]: string[] }
  ) => {
    closeModal();
    setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: true }));

    if (!selectedDealName) {
      console.error('Deal name (companyName) is missing.');
      setToastMsg('Company name is required to generate the report');
      setToastStatus(false);
      setShowToast(true);
      setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: false }));
      return;
    }
    try {
      const response = await apiCall(
        `/generate-report/${clientName}/company/${selectedDealName}`,
        {
          method: 'POST',
          data: { selectedFiles, format, reportType },
          responseType: 'blob',
          transformResponse: (res: any) => res,
        }
      );

      const fileExtension = format === 'word' ? 'docx' : 'pdf';
      const mimeType =
        format === 'word'
          ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          : 'application/pdf';

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: mimeType })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${selectedDealName}-Credit-Memo.${fileExtension}`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        console.error('Failed to generate report:', err);

        if (err.response) {
          console.error('Error Status:', err.response.status);
          console.error('Error Data:', err.response.data);
          console.error('Error Headers:', err.response.headers);
        } else if (err.request) {
          console.error('No response received:', err.request);
        } else {
          console.error('Error Message:', err.message);
        }
      } else {
        console.error('An unknown error occurred:', err);
      }

      setToastMsg('Failed to generate report');
      setToastStatus(false);
      setShowToast(true);
    } finally {
      setGeneratingReports((prevState) => ({ ...prevState, [selectedDealName]: false }));
    }
  };

  const handleRowDoubleClick = (name: string) => {
    setHeader(`Analyse Deal: ${name}`);
    setCompanyName(name);
  };

  return (
    <div className="w-full text-center">
      <table className="font-inter w-full border-gray-300 border rounded">
        <thead>
          <tr className="border-b border-gray-300">
            <th className="bg-gray-100 p-3 border-r border-gray-300 font-semibold">
              Name
            </th>
            <th className="bg-gray-100 p-3 font-semibold border-r border-gray-300">
              Delete
            </th>
            <th className="bg-gray-100 p-3 font-semibold">Report</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              className="hover:bg-gray-200 cursor-pointer"
              key={`${item}-${index + 1}`}
              onDoubleClick={() => handleRowDoubleClick(item)}
            >
              <td className="px-3 py-2">{item}</td>
              <td className="px-3 py-2 text-center">
                <img
                  src={delete_icon}
                  className="cursor-pointer"
                  style={{ display: 'block', margin: '0 auto' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the row click event
                    deleteDeal(item);
                  }}
                />
              </td>
              <td className="px-3 py-2 text-center">
                {generatingReports[item] ? (
                  <img
                    src={Spinner}
                    alt="Loading..."
                    className="w-8 h-8"
                    style={{ display: 'block', margin: '0 auto' }}
                  />
                ) : (
                  <img
                    src={ReportIcon}
                    alt="Generate Report"
                    className="cursor-pointer"
                    style={{ display: 'block', margin: '0 auto' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the row click event
                      generateReport(item);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <FileSelectionModal
        isModalOpen={isModalOpen}
        clientName={clientName}
        companyName={selectedDealName}
        closeModal={closeModal}
        handleGenerateDeal={handleGenerateDeal}
      />

      {showToast && (
        <ToastMessage
          isSuccess={toastStatus}
          setToast={setShowToast}
          toastMsg={toastMsg}
        />
      )}
    </div>
  );
};

export default DealTable;
