// src/components/monitoring/charts/DualAxisChartComponent.tsx
import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface DualAxisConfig {
  dataKey: string;
  stroke?: string;
  fill?: string;
  name: string;
  type?: 'bar' | 'line';
}

interface DualAxisChartComponentProps {
  data: { name: string; [key: string]: number | string }[];
  lines: DualAxisConfig[];
  bars: DualAxisConfig[];
  height?: number;
  width?: string | number;
  margin?: { top: number; right: number; left: number; bottom: number };
}

const formatYAxis = (value: number): string => {
  if (value >= 1_000_000_000) return `${(value / 1_000_000_000).toFixed(1)}B`;
  if (value >= 1_000_000) return `${(value / 1_000_000).toFixed(1)}M`;
  if (value >= 1_000) return `${(value / 1_000).toFixed(1)}K`;
  return value.toString();
};

const DualAxisChartComponent: React.FC<DualAxisChartComponentProps> = ({
  data,
  lines,
  bars,
  height = 300,
  width = '90%',
  margin = { top: 20, right: 50, left: 20, bottom: 5 },
}) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <ComposedChart data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ fontSize: 12 }} />
        <YAxis
          yAxisId="left"
          tickFormatter={formatYAxis}
          tick={{ fontSize: 12 }}
          orientation="left"
        />
        <YAxis
          yAxisId="right"
          tickFormatter={formatYAxis}
          tick={{ fontSize: 12 }}
          orientation="right"
        />
        <Tooltip formatter={(value: any) => formatYAxis(Number(value))} />
        <Legend wrapperStyle={{ fontSize: 12 }} />
        {bars.map((bar) => (
          <Bar
            key={bar.dataKey}
            yAxisId="left"
            dataKey={bar.dataKey}
            fill={bar.fill}
            name={bar.name}
          />
        ))}
        {lines.map((line) => (
          <Line
            key={line.dataKey}
            yAxisId="right"
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.stroke}
            name={line.name}
            activeDot={{ r: 6 }}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DualAxisChartComponent;
