// src\components\monitoring\initialize-monitoring-modal.tsx
import React, { useState } from 'react';
import { apiCall } from '../../utils/api.ts';

interface InitializeMonitoringModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;  // Add clientName as a prop to pass down
}

const InitializeMonitoringModal: React.FC<InitializeMonitoringModalProps> = ({
  setShowModal,
  clientName
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedYearEnd, setSelectedYearEnd] = useState<string>('');
  const [selectedFrequency, setSelectedFrequency] = useState<string>('Quarterly');
  const [companyName, setCompanyName] = useState<string>('');
  const [selectedPLPeriods, setSelectedPLPeriods] = useState<string[]>([]);
  const [selectedCFPeriods, setSelectedCFPeriods] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string>('EUR'); // Default to EUR
  const [showCompareSfaModal, setShowCompareSfaModal] = useState<boolean>(false);
  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    setFile(selectedFile || null);  // Set the selected file
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  };
  
  const handleYEChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedYearEnd(e.target.value);
  };

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFrequency(e.target.value);
  };

  // Update the function to handle multiple selections
  const handlePeriodSelect = (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
    const period = e.target.value;
    if (type === 'ProfitAndLoss' && !selectedPLPeriods.includes(period)) {
      setSelectedPLPeriods([...selectedPLPeriods, period]);
    } else if (type === 'CashFlow' && !selectedCFPeriods.includes(period)) {
      setSelectedCFPeriods([...selectedCFPeriods, period]);
    }
    e.target.value = ''; // Reset dropdown after selection
  };
  

  const removePeriod = (period: string, type: string) => {
    if (type === 'ProfitAndLoss') {
      setSelectedPLPeriods(selectedPLPeriods.filter(p => p !== period));  // Remove from P&L periods
    } else if (type === 'CashFlow') {
      setSelectedCFPeriods(selectedCFPeriods.filter(p => p !== period));  // Remove from CF periods
    }
  };
  
  
  const handleSubmit = async () => {
    // Close the modal immediately
    setShowModal(false);

    // Construct the tags object
    const tags = {
      ProfitAndLoss: selectedPLPeriods,  // Array of selected periods for P&L
      CashFlow: selectedCFPeriods,       // Array of selected periods for Cash Flow
    };

    // Debugging to check the state values
    console.log("File:", file);
    console.log("Company Name:", companyName);
    console.log("Report Date:", selectedDate);
    console.log("Currency:", currency);
    console.log("Report Frequency:", selectedFrequency);
    console.log("Year End:", selectedYearEnd);
    console.log("P&L reported periods:", selectedPLPeriods);
    console.log("CF reported periods:", selectedCFPeriods);
    if (!file || !companyName || !currency) {
      alert('Please fill all the fields and select a file');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('clientName', clientName);
      formData.append('companyName', companyName);
      formData.append('pdfFile', file);
      formData.append('pdfName', file.name);
      formData.append('currency', currency);
      formData.append('reportDate', selectedDate);
      formData.append('reportFrequency', selectedFrequency);
      formData.append('yearEnd', selectedYearEnd);
      formData.append('tags', JSON.stringify(tags)); 

      // Send the POST request using fetch
      const response = await apiCall('/initialize-monitoring', {
        method: 'POST',
        data: formData,  // Use 'data' instead of 'body'
        // headers: {
        //   'Content-Type': 'multipart/form-data',  // Ensure correct headers
        // },
      });

      const result = response;  // Access data directly

      if (response.success && result.message === 'Monitoring initialized successfully') {
        // setShowModal(false);
        alert('Monitoring initialized successfully');        
      } else {
        throw new Error(result.message || 'Initialization failed');
      }
    } catch (error: any) {
      alert('Error initializing monitoring');
      console.error('Error initializing monitoring:', error)
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/2 max-h-screen overflow-y-auto">
        <h2 className="text-lg font-semibold mb-4">Initialize New Monitoring</h2>
        <label htmlFor="companyName">Company Name</label>
        <input
            id="companyName"
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="block w-full mb-4"
            placeholder="Enter Company Name"
        />

        <label htmlFor="fileUpload">Upload File</label>
        <input
          id="fileUpload"
          type="file"
          onChange={handleFileChange}
          accept=".pdf"
          className="block w-full mb-4"
        />

        <label htmlFor="currency">Currency</label>
        <select
          id="currency"
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
        >
          <option value="EUR">EUR - Euro</option>
          <option value="USD">USD - US Dollar</option>
          <option value="GBP">GBP - British Pound</option>
          {/* Add more currencies as needed */}
        </select>


        <label htmlFor="reportDate">Report Date</label>
        <input
          id="reportDate"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className="block w-full mb-4"
        />

        <label htmlFor="reportDate">Next Year-end (vs Report Date)</label>
        <input
          id="yearEnd"
          type="date"
          value={selectedYearEnd}
          onChange={handleYEChange}
          className="block w-full mb-4"
        />

        <label htmlFor="reportFrequency">Report Frequency</label>
        <select
          id="reportFrequency"
          value={selectedFrequency}
          onChange={handleFrequencyChange}
          className="block w-full mb-4"
        >
          <option value="Monthly">Monthly</option>
          <option value="Quarterly">Quarterly</option>
        </select>


        <label htmlFor="reportPeriodsPL">Reported Periods (P&L)</label>
        <select
          id="reportPeriodsPL"
          onChange={(e) => handlePeriodSelect(e, 'ProfitAndLoss')}
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
        >
          <option value="">Select Period</option>
          <option value="Month">Month</option>
          <option value="Quarter">Quarter</option>
          <option value="YTD">Year-to-Date (YTD)</option>
          <option value="LTM">LTM</option>
        </select>

        {/* Display selected periods as tags */}
        <div className="flex flex-wrap gap-2 mb-4">
          {/* <h3>P&L Periods:</h3> */}
          {selectedPLPeriods.map((period, index) => (
            <span key={index} className="bg-gray-200 text-gray-800 font-semibold py-1 px-3 rounded-full flex items-center text-sm">
              {period}
              <button onClick={() => removePeriod(period, 'ProfitAndLoss')} className="ml-2 text-gray-600 font-bold">&times;</button>
            </span>
          ))}
        </div>

        <label htmlFor="reportPeriodsCF">Reported Periods (CF)</label>
        <select
          id="reportPeriodsCF"
          onChange={(e) => handlePeriodSelect(e, 'CashFlow')}
          className="block w-full mb-4 p-2 border border-gray-300 rounded"
        >
          <option value="">Select Period</option>
          <option value="Month">Month</option>
          <option value="Quarter">Quarter</option>
          <option value="YTD">Year-to-Date (YTD)</option>
          <option value="LTM">LTM</option>
        </select>

        <div className="flex flex-wrap gap-2 mb-4">
          {/* <h3>CF Periods:</h3> */}
          {selectedCFPeriods.map((period, index) => (
            <span key={index} className="bg-gray-200 text-gray-800 font-semibold py-1 px-3 rounded-full flex items-center text-sm">
              {period}
              <button onClick={() => removePeriod(period, 'CashFlow')} className="ml-2 text-gray-600 font-bold">&times;</button>
            </span>
          ))}
        </div>

        <div className="flex justify-end">
            <button onClick={handleSubmit} className="bg-blue-600 text-white px-4 py-2 rounded">
                Submit
            </button>
            <button onClick={() => setShowModal(false)} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
                Close
            </button>
        </div>
      </div>
    </div>
  );
};

export default InitializeMonitoringModal;