// FeedbackMessage.tsx
import React from 'react';

interface FeedbackMessageProps {
  error: string | null;
  successMessage: string | null;
}

const FeedbackMessage: React.FC<FeedbackMessageProps> = ({ error, successMessage }) => {
  return (
    <>
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Success</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>{successMessage}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackMessage;
