// src/components/monitoring/report-modal.tsx

import React, { useState, useEffect } from 'react';
import { apiCall } from '../../utils/api.ts';

interface ReportModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;
  companyName: string;
  onSelectReportDate: (reportDate: string, format: string) => void;  // Updated to include format
}

const ReportModal: React.FC<ReportModalProps> = ({
  setShowModal,
  clientName,
  companyName,
  onSelectReportDate,
}) => {
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedFormat, setSelectedFormat] = useState<string>('pdf'); // New state for format

  useEffect(() => {
    // Fetch available report_date fields for the selected company
    const fetchAvailableDates = async () => {
      try {
        const response = await apiCall(`/get-report-dates`, {
          method: 'GET',
          params: {
            collectionName: clientName,
            companyName: companyName,
          },
        });
        const result = response;  // Access data directly
        
        // Assuming result is an array of report_date strings
        setAvailableDates(result);
      } catch (error) {
        console.error('Failed to fetch available report dates:', error);
      }
    };

    fetchAvailableDates();
  }, [clientName, companyName]);

  const handleSubmit = () => {
    if (!selectedDate) {
      alert('Please select a report date.');
      return;
    }

    if (!selectedFormat) {
      alert('Please select a report format.');
      return;
    }

    onSelectReportDate(selectedDate, selectedFormat); // Pass both date and format
    setShowModal(false);  // Close the modal after selecting the date
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/2">
        <h2 className="text-lg font-semibold mb-4">Select Report Date and Format</h2>

        {availableDates.length > 0 ? (
          <>
            <div className="mb-4">
              <label htmlFor="reportDate" className="block text-sm font-medium text-gray-700">
                Report Date
              </label>
              <select
                id="reportDate"
                className="mt-1 block w-full p-2 border border-gray-300 rounded"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              >
                <option value="">Select a date</option>
                {availableDates.map((date, index) => (
                  <option key={index} value={date}>
                    {new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                  </option>
                ))}
              </select>
            </div>

            {/* Format Selection */}
            <div className="mb-4">
              <h3 className="font-medium mb-2">Select Report Format</h3>
              <div className="flex items-center">
                <label className="mr-4 flex items-center">
                  <input
                    type="radio"
                    name="reportFormat"
                    value="pdf"
                    checked={selectedFormat === 'pdf'}
                    onChange={(e) => setSelectedFormat(e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">PDF</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="reportFormat"
                    value="word"
                    checked={selectedFormat === 'word'}
                    onChange={(e) => setSelectedFormat(e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">Word</span>
                </label>
              </div>
            </div>
          </>
        ) : (
          <p>No available reports for this company.</p>
        )}

        <div className="flex justify-end">
          <button onClick={handleSubmit} className="bg-blue-600 text-white px-4 py-2 rounded">
            Generate Report
          </button>
          <button onClick={() => setShowModal(false)} className="bg-gray-300 text-black px-4 py-2 rounded ml-2">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
