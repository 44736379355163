// src\components\monitoring\Dashboard\ChartContainer.tsx
import React from 'react';
import classNames from 'classnames';
import BarChartComponent from './BarChartComponent';
import { FinancialData } from '../../types/skeletonTypes';
import { prepareChartData } from '../../../utils/prepareChartData';
import LineChartComponent from './LineChartComponent';
import DualAxisChartComponent from './DualAxisChartComponent'

interface ChartContainerProps {
  selectedCharts: string[];
  reportDataList: FinancialData[];
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  selectedCharts,
  reportDataList,
}) => {
  const revenueChartData = prepareChartData(reportDataList, ['ProfitAndLoss', 'Revenue', 'Group', 'month'], {
    nameKey: 'report_date',
    dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
  });

  const ebitdaChartData = prepareChartData(reportDataList, ['ProfitAndLoss', 'EBITDA', 'Group', 'month'], {
    nameKey: 'report_date',
    dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
  });

  const leverageChartData = prepareChartData(reportDataList, ['LeverageBreakdown', 'LeverageRatio', 'Group'], {
    nameKey: 'report_date',
    dataKeys: { actual: 'actual' },
  });

  const grossProfitChartData = prepareChartData(reportDataList, ['ProfitAndLoss', 'GrossProfit', 'Group', 'month'], {
    nameKey: 'report_date',
    dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
  });
  
  const FCFChartData = prepareChartData(reportDataList, ['CashFlow', 'FreeCashFlow', 'FreeCashFlow', 'Group', 'month'], {
    nameKey: 'report_date',
    dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
  });
  
  const grossMarginPercentageData = prepareChartData(
    reportDataList,
    ['ProfitAndLoss', 'GrossMarginPercentage', 'Group', 'month'],
    {
      nameKey: 'report_date',
      dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
    }
  );

  const ebitdaMarginPercentageData = prepareChartData(
    reportDataList,
    ['ProfitAndLoss', 'EBITDAMarginPercentage', 'Group', 'month'],
    {
      nameKey: 'report_date',
      dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
    }
  );

  const fcfComparisonData = prepareChartData(
    reportDataList,
    ['CashFlow', 'FreeCashFlow', 'FreeCashFlow', 'Group', 'month'],
    {
      nameKey: 'report_date',
      dataKeys: { actual: 'actual', budget: 'budget', priorYear: 'priorYear' },
    }
  );

  const cashFlowVsCapexData = prepareChartData(
    reportDataList,
    ['CashFlow', 'OperatingActivities', 'Group', 'month'],
    {
      nameKey: 'report_date',
      dataKeys: { actual: 'CashFlowFromOperatingActivities', capex: 'Capex' },
    }
  );

  const dscrData = prepareChartData(
    reportDataList,
    ['CashFlow', 'FreeCashFlow', 'DSCRRatio', 'Group', 'month'],
    {
      nameKey: 'report_date',
      dataKeys: { actual: 'DSCRRatio' },
    }
  );

  const chartsMap: { [key: string]: React.ReactElement } = {
    Revenue: (
      <BarChartComponent
        data={revenueChartData}
        bars={[
          { dataKey: 'actual', fill: '#5DADE2' },
          { dataKey: 'budget', fill: '#95A5A6' },
          { dataKey: 'priorYear', fill: '#58D68D' },
        ]}
      />
    ),
    EBITDA: (
      <BarChartComponent
        data={ebitdaChartData}
        bars={[
          { dataKey: 'actual', fill: '#6A0DAD' },
          { dataKey: 'budget', fill: '#FF5733' },
          { dataKey: 'priorYear', fill: '#3498DB' },
        ]}
      />
    ),
    'Gross Profit': (
      <BarChartComponent
        data={grossProfitChartData}
        bars={[
          { dataKey: 'actual', fill: '#28B463' },
          { dataKey: 'budget', fill: '#F4D03F' },
          { dataKey: 'priorYear', fill: '#7D3C98' },
        ]}
      />
    ),
    'Free Cash Flow': (
      <BarChartComponent
        data={FCFChartData}
        bars={[
          { dataKey: 'actual', fill: '#2874A6' },
          { dataKey: 'budget', fill: '#AAB7B8' },
          { dataKey: 'priorYear', fill: '#1ABC9C' },
        ]}
      />
    ),
    'Leverage Ratio': (
      <BarChartComponent
        data={leverageChartData}
        bars={[{ dataKey: 'actual', fill: '#2C3E50' }]}
      />
    ),

  'Gross Margin Percentage': (
      <LineChartComponent
        data={grossMarginPercentageData}
        lines={[
          { dataKey: 'actual', stroke: '#F1C40F', name: 'Actual' },
          { dataKey: 'budget', stroke: '#95A5A6', name: 'Budget' },
          { dataKey: 'priorYear', stroke: '#1ABC9C', name: 'Prior Year' },
        ]}
        // title="Gross Margin Percentage"
      />
    ),
    'EBITDA Margin Percentage': (
      <LineChartComponent
        data={ebitdaMarginPercentageData}
        lines={[
          { dataKey: 'actual', stroke: '#E74C3C', name: 'Actual' },
          { dataKey: 'budget', stroke: '#95A5A6', name: 'Budget' },
          { dataKey: 'priorYear', stroke: '#1ABC9C', name: 'Prior Year' },
        ]}
        // title="EBITDA Margin Percentage"
      />
    ),
    'Free Cash Flow Comparison': (
      <LineChartComponent
        data={fcfComparisonData}
        lines={[
          { dataKey: 'actual', stroke: '#2874A6', name: 'Actual' },
          { dataKey: 'budget', stroke: '#AAB7B8', name: 'Budget' },
          { dataKey: 'priorYear', stroke: '#1ABC9C', name: 'Prior Year' },
        ]}
        // title="Free Cash Flow Comparison"
      />
    ),
    'Cash Flow vs Capex': (
      <DualAxisChartComponent
        data={cashFlowVsCapexData}
        lines={[
          { dataKey: 'CashFlowFromOperatingActivities', stroke: '#2980B9', name: 'Operating Cash Flow' },
        ]}
        bars={[
          { dataKey: 'Capex', fill: '#E67E22', name: 'Capex' },
        ]}
        // title="Cash Flow from Operations vs Capex"
      />
    ),
    DSCR: (
      <LineChartComponent
        data={dscrData}
        lines={[
          { dataKey: 'DSCRRatio', stroke: '#2ECC71', name: 'DSCR' },
        ]}
        // title="Debt Service Coverage Ratio (DSCR)"
      />
    ),
  };

  const shouldExpandChart = (chartName: string) => {
    const chartElement = chartsMap[chartName];
    if (!chartElement || !chartElement.props || !chartElement.props.data) {
      console.warn(`Chart "${chartName}" not found or missing props`);
      return false;
    }
    const chartData = chartElement.props.data;
    return chartData.length > 6;
  };

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {selectedCharts.map((chartName) => (
        <div
          key={chartName}
          className={classNames(
            'bg-white shadow rounded-md p-4 overflow-hidden transition-all duration-300 ease-in-out text-sm',
            {
              'lg:col-span-2': shouldExpandChart(chartName),
            }
          )}
          style={{ maxHeight: '350px', minWidth: '250px' }}
        >
          <h3 className="text-sm font-medium mb-2">{chartName}</h3>
          <div className="w-full h-full flex items-center justify-center">
            {chartsMap[chartName] || <p className="text-gray-400">Chart not available.</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartContainer;
