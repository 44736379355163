// client/src/utils/fieldUtils.ts

/**
 * Determines if a field is editable based on editable prefixes.
 * If the field path starts with any of the editable prefixes, it's editable.
 * @param fieldPath - An array representing the path to the field.
 * @param editablePrefixes - An array of editable field path prefixes.
 * @returns `true` if the field is editable, `false` otherwise.
 */
export const isFieldEditable = (
    fieldPath: string[],
    editablePrefixes: string[]
  ): boolean => {
    for (const prefix of editablePrefixes) {
      const prefixSegments = prefix.split('.');
      if (prefixSegments.length > fieldPath.length) continue;
  
      let match = true;
      for (let i = 0; i < prefixSegments.length; i++) {
        if (prefixSegments[i] !== fieldPath[i]) {
          match = false;
          break;
        }
      }
  
      if (match) {
        return true; // Field is under an editable prefix
      }
    }
    return false; // Field is not editable
  };
  