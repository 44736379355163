import React from 'react';

interface DateRangePickerProps {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  fetchData: () => Promise<void>;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  fetchData,
}) => {
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleFetchData = () => {
    fetchData();
  };

  return (
    <div className="bg-white shadow rounded-lg p-6 flex flex-col sm:flex-row sm:flex-wrap items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 max-w-full overflow-hidden">
      <div className="flex flex-col w-full sm:w-auto">
        <label htmlFor="startDate" className="text-sm font-medium text-gray-700">
          Start Date
        </label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={handleStartDateChange}
          className="mt-1 block w-full sm:w-auto border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="flex flex-col w-full sm:w-auto">
        <label htmlFor="endDate" className="text-sm font-medium text-gray-700">
          End Date
        </label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={handleEndDateChange}
          className="mt-1 block w-full sm:w-auto border border-gray-300 rounded-md shadow-sm p-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="w-full sm:w-auto mt-4 sm:mt-0">
        <button
          onClick={handleFetchData}
          className="w-full sm:w-auto inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Fetch Data
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;
