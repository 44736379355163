// src/utils/prepareChartData.ts
export const prepareChartData = (
    reportDataList: any[],
    dataPath: string[],
    keys: { nameKey: string; dataKeys: { [key: string]: string } }
  ) => {
    return reportDataList.map((report) => {
      const name = report?.[keys.nameKey] || 'N/A';
      const chartData = { name };
  
      Object.entries(keys.dataKeys).forEach(([key, property]) => {
        chartData[key] = dataPath.reduce((acc, path) => acc?.[path], report)?.[property] ?? 0;
      });
  
      return chartData;
    });
  };
  