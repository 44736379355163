// src\components\deal monitoring\analyse-deal.tsx
import React, { useState, useEffect, useRef, FormEvent } from 'react';
import Input from '../ui/input/input';
import send from '../../images/icon _send_message.svg';
import Message from '../ui/message/message';
import { MessageProps } from '../ui/message/message';
import axios from 'axios';
import BrightSpinner from '../../images/Spinner.gif';
import ReactMarkdown from 'react-markdown';
import ImageModal from '../ui/image-modal';
import DarkSpinner from '../../images/DarkSpinner.gif';
import { apiCall } from '../../utils/api';

interface AnalyseDealProps {
  companyName: string;
  clientName: string;  // Include clientName in props
}

const AnalyseDeal: React.FC<AnalyseDealProps> = ({ companyName, clientName }) => {
  const [messageList, setMessageList] = useState<MessageProps[]>([]);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [filenames, setFilenames] = useState<string[]>([]);
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  
  useEffect(() => {
    if (clientName && companyName) {
      fetchFilenames(clientName, companyName);  // Call fetchFilenames with both clientName and companyName
    }
  }, [clientName, companyName]);  // Include both clientName and companyName in dependencies
  

  const fetchFilenames = async (clientName: string, companyName: string) => {
    try {
      console.log(`Fetching filenames for clientName: ${clientName}, companyName: ${companyName}`);
        // Use apiCall instead of axios.get
        const response = await apiCall(`/filenames/${encodeURIComponent(clientName)}`, {
          params: { companyName }
        });
        setFilenames(response);  // Assuming response contains the filenames directly
    } catch (err) {
        console.error('Failed to fetch filenames:', err);
    }
  };

  const cleanMessage = (message: string): string => {
    const relatedDocIndex = message.indexOf("**Related Documents:**");
    if (relatedDocIndex === -1) return message; // If there is no "Related Documents", return the message as is
  
    return message.substring(0, relatedDocIndex).trim(); // Remove everything after "Related Documents"
  };

  const handleSend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent the form from submitting traditionally
    if (message.trim() === '') {
      alert('Please enter a message before sending.');
      return;
    }
  
    setLoading(true);
  
    try {
      // Construct chat history in the format expected by your backend API
      const chatHistory = messageList.map(chat => ({
        role: chat.personName === 'User' ? 'user' : 'assistant',  // Differentiate between user and AI messages
        content: cleanMessage(chat.message) // Clean up each message from Related Documetns
      }));
  
      const response = await apiCall(`/chat`, {
        method: 'POST',
        data: {
          collectionName: clientName,
          companyName,
          message,
          chatHistory,  // Use the properly formatted chatHistory
          filenames: selectedFilenames,
        }
      });
    
      // Check if the response contains the `reply` field
      const linkedResponse = response?.reply
      ? linkifyFilenames(response.reply)
      : 'No reply received'; // Handle the case where the reply field is missing

      setMessageList((prev) => [
        ...prev,
        { personName: 'User', message },
        { personName: 'AI', message: linkedResponse },  // store the processed message in state
      ]);
      setMessage('');
    } catch (err) {
      console.error('Failed to send message:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const linkifyFilenames = (message: string) => {
    const relatedDocIndex = message.indexOf("**Related Documents:**");
    if (relatedDocIndex === -1) return message; // Return the original message if the tag isn't found
  
    const beforeRelatedDocs = message.substring(0, relatedDocIndex);
    const relatedDocsAndAfter = message.substring(relatedDocIndex);
  
    // Extract filenames and remove the "**Related Documents:** " part
    const filenames = relatedDocsAndAfter.replace("**Related Documents:** ", "").split(",").map(filename => filename.trim());
    
    // Create a markdown link for each filename
    const linkedFilenames = filenames.map((filename: string) => 
      `[${filename}](#${filename})`
    ).join(", ");
  
    return `${beforeRelatedDocs}**Related Documents:** ${linkedFilenames}`;
  };
  
  const handleLinkClick = async (filename: string) => {
    try {
      const response = await apiCall(`/slidevisual`, {
        params: {
          clientName,
          companyName,
          filename,
        },
        responseType: 'blob', // Fetch the image as a blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setImageUrl(url); // Set the blob URL for the ImageModal
    } catch (error) {
      console.error('Failed to fetch file:', error);
      alert('Failed to retrieve the file. Please try again later.');
    }
  };

  const handleFilenameSelection = (filename: string) => {
    if (selectedFilenames.includes(filename)) {
      setSelectedFilenames(selectedFilenames.filter((name) => name !== filename));
    } else {
      setSelectedFilenames([...selectedFilenames, filename]);
    }
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messageList]);

  return (
    <div className='flex-shrink-0 flex flex-col justify-between h-[calc(100vh_-_77px)]'>
      {/* Filename selection */}
      <div className='w-full border border-gray-300 p-2'>
        <h4>Select Filenames:</h4>
        <div className='flex flex-wrap'>
          {filenames.map((filename, index) => (
            <label key={index} className='mr-4'>
              <input
                type="checkbox"
                value={filename}
                onChange={() => handleFilenameSelection(filename)}
                checked={selectedFilenames.includes(filename)}
              />
              {filename}
            </label>
          ))}
        </div>
      </div>

      {/* Chat history */}
      <div
        ref={chatHistoryRef}
        className='w-full border border-gray-300 overflow-auto grow flex flex-col gap-2 p-2'
        // style={{ maxHeight: '50%' }}
      >
        {messageList.map((item, index) => (
          <div
            key={index}
            style={{
              textAlign: item.personName === 'User' ? 'right' : 'left',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                display: 'inline-block',
                backgroundColor: item.personName === 'User' ? '#007bff' : '#444',
                color: 'white',
                padding: '10px',
                borderRadius: '10px',
                maxWidth: '60%',
              }}
            >
              <ReactMarkdown
                children={item.message}
                components={{
                  a: ({ node, ...props }) => (
                    <a
                      {...props}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!node || !node.children || !node.children[0]) return; // Type guard to ensure node and children are defined
                        const filename = (node.children[0] as any).value; // Extract the filename from the link
                        handleLinkClick(filename); // Use companyName directly as companyName
                      }}
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      {props.children}
                    </a>
                  ),
                  ol: ({ node, ...props }) => (
                    <ol className="list-decimal pl-5" {...props} />
                  ), // Ordered list styling
                  ul: ({ node, ...props }) => (
                    <ul className="list-disc pl-5" {...props} />
                  ), // Unordered list styling
                  li: ({ node, ...props }) => (
                    <li className="mb-2" {...props} />
                  ), // List item styling
                }}
              />
            </div>
          </div>
        ))}
        {loading && (
          <div className="text-center py-4">
            <img src={DarkSpinner} alt="Loading..." className="w-12 h-12 mx-auto" />
          </div>
        )}
      </div>
      {imageUrl && (
        <ImageModal
          imageUrl={imageUrl}
          onClose={() => setImageUrl(null)}  // Close modal by setting imageUrl to null
        />
      )}

      {/* Message input */}
      <div className='w-full my-6 flex border border-black p-2 items-center gap-2'>
        <div className='flex grow gap-2'>
          <Input
            className='outline-none grow border border-black p-2 rounded'
            placeholder='Message Credilyst'
            type='text'
            onChange={(e) => setMessage(e.target.value)}
            disabled={loading || selectedFilenames.length === 0}
            value={message}
          />
        </div>
        <button
          onClick={handleSend}
          disabled={message?.trim()?.length === 0 || loading || selectedFilenames.length === 0}
          className='disabled:opacity-50 bg-gray-400 p-2 rounded-md cursor-pointer flex gap-1 items-center'
        >
          <img src={send} alt="Send" />
          {loading && <img src={BrightSpinner} className='w-8 h-8' />}
        </button>
      </div>
    </div>
  );
};

export default AnalyseDeal;
