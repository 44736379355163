// src/components/data-extraction/DataExtractionMain.tsx

import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { apiCall } from '../../utils/api';
import DataExtraction from './DataExtraction';
import { getClientName } from '../../utils/authUtils';
import PageHeader from '../ui/pageHeader/pageHeader'; // Adjust the import path as needed
import { FaSpinner } from 'react-icons/fa';

const DataExtractionMain: React.FC = () => {
  const [tableData, setTableData] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('Data Extraction');
  const [clientName, setClientName] = useState<string>('');

  // Fetch client name on component mount
  useEffect(() => {
    (async () => {
      const name = await getClientName();
      if (name) setClientName(name);
    })();
  }, []);

  // Fetch company names based on the client name
  const { data, error, isLoading } = useQuery({
    queryKey: ['portfolio-data', clientName],
    queryFn: async () => {
      if (!clientName) return [];
      const response = await apiCall('/portfolio', {
        params: { collectionName: clientName },
      });
      // apiCall already returns response.data
      return response; // Assuming response is an array of company names
    },
    enabled: !!clientName, // Ensure the query runs only when clientName is available
  });

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setTableData(data); // Ensure data is an array
    } else {
      console.error('Expected data to be an array but received:', data);
      setTableData([]); // Fallback to empty array
    }
  }, [data]);

  return (
    <div className='py-2 px-4 font-inter w-full overflow-auto h-screen'>
      <div className='flex w-full items-center'>
        {title !== 'Data Extraction' && (
          <p
            className='transform rotate-180 font-inter text-3xl cursor-pointer'
            onClick={() => setTitle('Data Extraction')}
          >
            ➜
          </p>
        )}
        <div className='grow'>
          <PageHeader title={title} />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <FaSpinner className="animate-spin text-blue-500" size={30} />
          <p className="ml-2">Loading portfolio data...</p>
        </div>
      ) : (
        title === 'Data Extraction' ? (
          <div className="p-4">
            {/* Render DataExtraction only if clientName and tableData are available */}
            {clientName && tableData.length > 0 ? (
              <DataExtraction clientName={clientName} deals={tableData} />
            ) : (
              <p>No deals available for the selected client.</p>
            )}
          </div>
        ) : (
          <div>
            {/* You can add other components or content here if needed */}
          </div>
        )
      )}
      {error && (
        <div className="flex justify-center items-center h-full">
          <p className="text-red-500">Failed to load portfolio data.</p>
        </div>
      )}
    </div>
  );
};

export default DataExtractionMain;
