import React from 'react';
import Login from '../components/login/login';

const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;
