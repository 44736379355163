// src\components\monitoring\deal-monitor.tsx
import React, { useEffect, useRef, useState, useCallback } from 'react';
import DateRangePicker from './Dashboard/DateRangePicker';
import ChartPortfolio from './Dashboard/ChartPortfolio';
import ChartContainer from './Dashboard/ChartContainer';
import DataTable from './Dashboard/DataTable';
import SaveButton from './Dashboard/SaveButton';
import FeedbackMessage from './Dashboard/FeedbackMessage';
import LoadingIndicator from './Dashboard/LoadingIndicator';
import SummaryTable from './Dashboard/SummaryTable';
import { FinancialData, financialDataSchema } from '../types/skeletonTypes';
import { apiCall } from '../../utils/api';
import { z } from 'zod';
import ToastMessage from '../ui/toastMessage/toast-message';
interface DealMonitorProps {
  companyName: string;
  clientName: string;
  reportDate: string;
}

const DealMonitor: React.FC<DealMonitorProps> = ({ companyName, clientName, reportDate }) => {
  // State Variables
  const [reportDataList, setReportDataList] = useState<FinancialData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>(''); 
  const [endDate, setEndDate] = useState<string>('');
  const [modifiedCells, setModifiedCells] = useState<Set<string>>(new Set());
  const [selectedCharts, setSelectedCharts] = useState<string[]>(['Revenue', 'EBITDA', 'Leverage Ratio']);

  const [selectedCompany, setSelectedCompany] = useState<string>('');  // Track the selected company
  const [selectedReportDate, setSelectedReportDate] = useState<string>('');  // Store the selected report date
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [toastStatus, setToastStatus] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setError(null); // Clear any previous errors before a new fetch
    setLoading(true);

    if (!startDate || !endDate) {
      setError('Start and end dates must be set before fetching data.');
      setLoading(false);
      return;
    }

    try {
      const response = await apiCall('/company-documents-range', {
        params: {
          collectionName: clientName,
          companyName,
          startDate,
          endDate,
        },
      });

      const parsedData = z.array(financialDataSchema).parse(response);
      setReportDataList(parsedData);
      setModifiedCells(new Set());

      // Clear any previous error after a successful fetch
      setError(null);
    } catch (error) {
      if (error instanceof z.ZodError) {
        console.error("Zod Validation Error:", error.errors);
        setError('Received invalid data format from the server.');
      } else {
        console.error("API Call Error:", error);
        setError('Failed to fetch data. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  }, [clientName, companyName, startDate, endDate]);

  const toggleChartSelection = (chart: string) => {
    setSelectedCharts((prev) =>
      prev.includes(chart) ? prev.filter((c) => c !== chart) : [...prev, chart]
    );
  };

  // First useEffect: Set the start and end dates
  useEffect(() => {
    if (reportDate) {
      setStartDate(reportDate);
      setEndDate(reportDate);
    }
  }, [reportDate]);

  // Second useEffect: Fetch data after dates are set
  useEffect(() => {
    if (reportDate) {
      fetchData();
    }
  }, [fetchData, reportDate]);

  // Handler functions for DealMonitor
  const handleSelectReportDate = async (selectedDate: string) => {
    setSelectedReportDate(selectedDate);
    // Implement report date selection logic here
  };

  const openModal = (companyName: string) => {
    setSelectedCompany(companyName);
    setShowUpdateModal(true);
  };

  const openGenerateReportModal = (companyName: string) => {
    setSelectedCompany(companyName);
    setShowReportModal(true);
  };

  // Pass all necessary props to child components
  return (
    <div className="space-y-6">
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        fetchData={fetchData}
      />
      <FeedbackMessage
        error={error}
        successMessage={successMessage}
      />
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
          <SummaryTable 
            reportDataList={reportDataList} 
          />
          <ChartPortfolio
            selectedCharts={selectedCharts}
            toggleChartSelection={toggleChartSelection}
          />
          <ChartContainer
            selectedCharts={selectedCharts}
            reportDataList={reportDataList}
          />
          <SaveButton
            modifiedCells={modifiedCells}
            reportDataList={reportDataList}
            clientName={clientName}
            companyName={companyName}
            setError={setError}
            setSuccessMessage={setSuccessMessage}
            setLoading={setLoading}
          />
          <DataTable
            reportDataList={reportDataList}
            setReportDataList={setReportDataList}
            modifiedCells={modifiedCells}
            setModifiedCells={setModifiedCells}
            clientName={clientName}
            companyName={companyName}
          />
        </>
      )}


      {/* Toast Message */}
      {showToast && (
        <ToastMessage isSuccess={toastStatus} toastMsg={toastMsg} setToast={setShowToast} />
      )}
    </div>
  );
};

export default DealMonitor;
