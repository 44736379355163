// src\pages\dealAnalysisPage.tsx
import React from 'react';
import Layout from '../components/ui/layout/layout';
import DealAnalysisMain from '../components/deal analysis/deal-analysis-main';


const dealAnalysisPage = () => {
  
  return (
    <Layout>
      <DealAnalysisMain />
    </Layout>
  );
};

export default dealAnalysisPage;
