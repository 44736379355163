// src/components/data-extraction/DataExtraction.tsx

import React, { useState, useEffect } from 'react';
import { apiCall } from '../../utils/api';
import ImageModal from '../ui/image-modal'; // Ensure correct import path
import { FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // TO BE REMOVED if not needed

interface DataExtractionProps {
  clientName: string;
  deals: string[]; // List of deals passed from DataExtractionMain
}

interface Slide {
  id: string; // unique identifier
  filename: string;
}

const DataExtraction: React.FC<DataExtractionProps> = ({ clientName, deals }) => {
  // State for deal selection
  const [selectedProject, setSelectedProject] = useState<string>('');

  // State for file selection
  const [filenames, setFilenames] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');

  // State for slides
  const [slides, setSlides] = useState<Slide[]>([]);
  const [slideSelection, setSlideSelection] = useState<Slide[]>([]);
  const [selectedSlideId, setSelectedSlideId] = useState<string>('');
  const [selectedSelectionSlideId, setSelectedSelectionSlideId] = useState<string>('');

  // Modal and image states
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [imageError, setImageError] = useState<string>('');

  // Loading and submitting states
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const [isLoadingSlides, setIsLoadingSlides] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Utility function to extract page number from filename
  const extractPageNumber = (filename: string): number => {
    const regex = /page_(\d+)/i;
    const match = filename.match(regex);
    return match ? parseInt(match[1], 10) : NaN;
  };

  // Helper function to insert a slide into the slides array in order
  const insertSlideInOrder = (slides: Slide[], slideToAdd: Slide): Slide[] => {
    const pageToAdd = extractPageNumber(slideToAdd.filename);
    if (isNaN(pageToAdd)) {
      // If the page number is unknown, append to the end
      return [...slides, slideToAdd];
    }

    // Find the index where the slide should be inserted
    const index = slides.findIndex((s) => {
      const page = extractPageNumber(s.filename);
      return !isNaN(page) && page > pageToAdd;
    });

    if (index === -1) {
      // If no higher page number is found, append to the end
      return [...slides, slideToAdd];
    } else {
      // Insert at the found index
      return [
        ...slides.slice(0, index),
        slideToAdd,
        ...slides.slice(index),
      ];
    }
  };

  // Handle removing a slide from Slide Selection back to Slide List
  const handleRemoveFromSelection = () => {
    if (!selectedSelectionSlideId) {
      alert('Please select a slide to remove.');
      console.log('Remove from selection clicked without selecting a slide.');
      return;
    }
    const slideToRemove = slideSelection.find((s) => s.id === selectedSelectionSlideId);
    if (slideToRemove) {
      console.log('Removing slide from selection:', slideToRemove);
      setSlides((prevSlides) => insertSlideInOrder(prevSlides, slideToRemove));
      setSlideSelection((prevSelection) => prevSelection.filter((s) => s.id !== slideToRemove.id));
      setSelectedSelectionSlideId('');
      setImageUrl('');
      setImageError('');
    } else {
      console.warn(`Slide with ID ${selectedSelectionSlideId} not found in slide selection.`);
    }
  };

  // Function to fetch filenames based on clientName and selectedProject
  const fetchFilenames = async () => {
    setIsLoadingFiles(true);
    console.log(`Fetching filenames for client: ${clientName}, project: ${selectedProject}`);
    try {
      const response = await apiCall(`/filenames/${encodeURIComponent(clientName)}`, {
        params: { companyName: selectedProject }, // Ensure 'companyName' is used
      });
      console.log('Filenames fetched:', response);
      // Ensure response is an array
      if (Array.isArray(response)) {
        setFilenames(response); // Assuming response is an array of filenames
      } else {
        console.error('Expected filenames to be an array but received:', response);
        setFilenames([]); // Fallback to empty array
      }
    } catch (err) {
      console.error('Failed to fetch filenames:', err);
      alert('Failed to load files. Please try again.');
      setFilenames([]); // Fallback to empty array on error
    } finally {
      setIsLoadingFiles(false);
    }
  };

  // Fetch filenames whenever clientName or selectedProject changes
  useEffect(() => {
    if (clientName && selectedProject) {
      fetchFilenames();
    } else {
      console.log('Client name or project is missing.');
      // Reset filenames and related states if project is deselected
      setFilenames([]);
      setSelectedFile('');
      setSlides([]);
      setSlideSelection([]);
      setSelectedSlideId('');
      setSelectedSelectionSlideId('');
      setImageUrl('');
      setImageError('');
    }
  }, [clientName, selectedProject]);

  // Function to fetch slides based on selectedFile
  const fetchSlides = async () => {
    if (!selectedFile) {
      console.log('No file selected. Skipping slide fetch.');
      return;
    }
    setIsLoadingSlides(true);
    console.log(`Fetching slides for file: ${selectedFile}`);
    try {
      const response = await apiCall('/slides', {
        params: {
          clientName,
          companyName: selectedProject, // Ensure 'companyName' is used
          filename: selectedFile,
        },
      });
      console.log('Slides fetched from API:', response.slides);

      const fetchedSlides = response.slides as { id?: string; filename: string }[];

      // Ensure slide IDs are unique by extracting page numbers
      const uniqueSlides: Slide[] = fetchedSlides.map((slide, index) => {
        const pageNumber = extractPageNumber(slide.filename);
        let uniqueId: string;

        if (!isNaN(pageNumber)) {
          uniqueId = `page_${pageNumber}`; // Use page number as ID
        } else {
          uniqueId = uuidv4(); // Fallback to unique ID if page number is unknown
        }

        console.log(`Slide ${index + 1}: ID = ${uniqueId}, Filename = ${slide.filename}`);
        return {
          id: uniqueId,
          filename: slide.filename,
        };
      });

      // Check for duplicate IDs
      const ids = uniqueSlides.map((slide) => slide.id);
      const uniqueIds = new Set(ids);
      if (ids.length !== uniqueIds.size) {
        console.warn('Duplicate slide IDs detected:', ids);
      } else {
        console.log('All slide IDs are unique.');
      }

      // Sort slides by page number ascending
      uniqueSlides.sort((a, b) => {
        const pageA = extractPageNumber(a.filename);
        const pageB = extractPageNumber(b.filename);
        if (isNaN(pageA) && isNaN(pageB)) return 0;
        if (isNaN(pageA)) return 1; // Place unknown pages at the end
        if (isNaN(pageB)) return -1;
        return pageA - pageB;
      });

      console.log('Slides after sorting:', uniqueSlides);

      setSlides(uniqueSlides);
      setSlideSelection([]); // Reset slide selection when a new file is selected
      setSelectedSlideId(''); // Reset selected slide ID
      setSelectedSelectionSlideId(''); // Reset selected slide in selection
      setImageUrl(''); // Reset image URL
      setImageError('');
    } catch (error) {
      console.error('Failed to fetch slides:', error);
      alert('Failed to load slides. Please try again.');
    } finally {
      setIsLoadingSlides(false);
    }
  };

  // Fetch slides whenever selectedFile changes
  useEffect(() => {
    if (selectedFile) {
      fetchSlides();
    } else {
      // Reset all related states if no file is selected
      console.log('Selected file cleared. Resetting slides and selections.');
      setSlides([]);
      setSlideSelection([]);
      setSelectedSlideId('');
      setSelectedSelectionSlideId('');
      setImageUrl('');
      setImageError('');
    }
  }, [selectedFile]);

  // Handle image fetching
  const handleLinkClick = async (filename: string) => {
    console.log(`Fetching image for filename: ${filename}`);
    try {
      setIsImageLoading(true);
      setImageError('');
      const response = await apiCall(`/slidevisual`, {
        params: {
          clientName,
          companyName: selectedProject, // Ensure 'companyName' is used
          filename,
        },
        responseType: 'blob', // Fetch the image as a blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log('Image URL created:', url);
      setImageUrl(url); // Set the blob URL for the ImageModal
    } catch (error: any) {
      console.error('Failed to fetch image:', error);
      if (error.response && error.response.status === 404) {
        setImageError('Slide image not found.');
      } else {
        setImageError('Failed to retrieve the image. Please try again later.');
      }
      setImageUrl('');
    } finally {
      setIsImageLoading(false);
    }
  };

  // Handle selecting a slide from the Slide List
  const handleSelectSlide = (slideId: string) => {
    console.log(`Selecting slide with ID: ${slideId}`);
    setSelectedSlideId(slideId);
    const slide = slides.find((s) => s.id === slideId);
    if (slide) {
      console.log(`Slide selected:`, slide);
      handleLinkClick(slide.filename); // Fetch the image when a slide is selected
    } else {
      console.warn(`Slide with ID ${slideId} not found in slides.`);
    }
  };

  // Handle adding the selected slide to Slide Selection
  const handleAddToSelection = () => {
    if (!selectedSlideId) {
      alert('Please select a slide to add.');
      console.log('Add to selection clicked without selecting a slide.');
      return;
    }
    const slideToAdd = slides.find((s) => s.id === selectedSlideId);
    if (slideToAdd) {
      console.log('Adding slide to selection:', slideToAdd);
      setSlideSelection((prev) => [...prev, slideToAdd]);
      setSlides((prev) => prev.filter((s) => s.id !== slideToAdd.id));
      setSelectedSlideId('');
      setImageUrl('');
      setImageError('');
    } else {
      console.warn(`Slide with ID ${selectedSlideId} not found in slides.`);
    }
  };

  // Handle selecting a slide in Slide Selection
  const handleSelectSelectionSlide = (slideId: string) => {
    console.log(`Selecting slide in selection with ID: ${slideId}`);
    setSelectedSelectionSlideId(slideId);
    const slide = slideSelection.find((s) => s.id === slideId);
    if (slide) {
      console.log(`Slide selected in selection:`, slide);
      handleLinkClick(slide.filename); // Fetch the image when a slide is selected
    } else {
      console.warn(`Slide with ID ${slideId} not found in slide selection.`);
    }
  };

  // Handle Submit
  const handleSubmit = async () => {
    const selectedSlides = slideSelection;
    if (selectedSlides.length === 0) {
      alert('Please select at least one slide.');
      console.log('Submit clicked without any selected slides.');
      return;
    }

    console.log('Submitting selected slides:', selectedSlides);
    try {
      setIsSubmitting(true);

      // Prepare payload with structured slide data
      const payload = selectedSlides.map((slide) => ({
        clientName,
        companyName: selectedProject, // Ensure 'companyName' is used
        filename: selectedFile, // Use filename as-is
        pageNumber: extractPageNumber(slide.filename), // Extract the page number
      }));
      console.log('Payload for submission:', payload);

      // Call backend to process slides
      const response = await apiCall('/process-slides', {
        method: 'POST',
        data: payload,
        responseType: 'blob', // Expecting a file
      });
      console.log('Response received from slide processing:', response);

      // Create a download link
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      console.log('Download URL created:', url);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'extracted_tables.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);

      console.log('Slides processed successfully and downloaded as Excel file.');
      alert('Slides processed successfully and downloaded as Excel file.');
    } catch (error) {
      console.error('Error processing slides:', error);
      alert('Failed to process slides. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4 flex flex-col h-full">
      {/* Deal and File Selection Dropdowns */}
      <div className="flex flex-col lg:flex-row gap-4 mb-4">
        {/* Deal Selection Dropdown */}
        <div className="flex flex-col flex-1">
          <label htmlFor="deal-select" className="mb-1 font-semibold">
            Select Deal
          </label>
          <select
            id="deal-select"
            value={selectedProject}
            onChange={(e) => {
              console.log(`Deal selected: ${e.target.value}`);
              setSelectedProject(e.target.value);
            }}
            className="p-2 border border-gray-300 rounded"
          >
            <option value="">-- Select Deal --</option>
            {deals.map((deal: string) => (
              <option key={deal} value={deal}>
                {deal}
              </option>
            ))}
          </select>
        </div>

        {/* File Selection Dropdown */}
        {selectedProject && (
          <div className="flex flex-col flex-1">
            <label htmlFor="file-select" className="mb-1 font-semibold">
              Select File
            </label>
            <select
              id="file-select"
              value={selectedFile}
              onChange={(e) => {
                console.log(`File selected: ${e.target.value}`);
                setSelectedFile(e.target.value);
              }}
              className="p-2 border border-gray-300 rounded"
            >
              <option value="">-- Select File --</option>
              {filenames.map((file: string) => (
                <option key={file} value={file}>
                  {file}
                </option>
              ))}
            </select>
            {isLoadingFiles && <FaSpinner className="animate-spin text-blue-500 mt-2" />}
          </div>
        )}
      </div>

      {/* Main Content: Two Columns */}
      <div className="flex flex-col md:flex-row flex-1 gap-4 overflow-hidden">
        {/* Left Column: Slide List and Slide Selection */}
        <div className="flex flex-col w-full md:w-1/3 gap-4">
          {/* Slide List */}
          <div
            className="border p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 h-72" // Fixed height using Tailwind
          >
            <h3 className="mb-4 font-semibold">Slide List</h3>

            {isLoadingSlides ? (
              <div className="flex items-center justify-center">
                <FaSpinner className="animate-spin text-blue-500" />
                <span className="ml-2">Loading slides...</span>
              </div>
            ) : slides.length > 0 ? (
              <div className="flex flex-col gap-2">
                {slides.map((slide) => (
                  <button
                    key={slide.id} // Now unique
                    onClick={() => handleSelectSlide(slide.id)}
                    className={`w-full text-left p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      selectedSlideId === slide.id
                        ? 'bg-blue-100'
                        : 'bg-white hover:bg-gray-100'
                    }`}
                    aria-pressed={selectedSlideId === slide.id}
                  >
                    {`Page ${isNaN(extractPageNumber(slide.filename)) ? 'Unknown' : extractPageNumber(slide.filename)}`}
                  </button>
                ))}
              </div>
            ) : (
              <p>No slides available.</p>
            )}
          </div>

          {/* Add to Selection Button */}
          <button
            onClick={handleAddToSelection}
            disabled={!selectedSlideId}
            className={`mb-4 px-4 py-2 rounded ${
              !selectedSlideId
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
          >
            Add to Selection
          </button>

          {/* Slide Selection */}
          {selectedFile && (
            <div
              className={`flex-1 border p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 h-96`} // Fixed height using Tailwind
            >
              <h3 className="mb-4 font-semibold">Slide Selection</h3>

              {/* Remove from Selection Button */}
              <button
                onClick={handleRemoveFromSelection}
                disabled={!selectedSelectionSlideId}
                className={`mb-4 px-4 py-2 rounded ${
                  !selectedSelectionSlideId
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-red-600 hover:bg-red-700 text-white'
                }`}
              >
                Remove from Selection
              </button>

              {slideSelection.length > 0 ? (
                <div className="flex flex-col gap-2">
                  {slideSelection.map((slide) => (
                    <button
                      key={slide.id}
                      onClick={() => handleSelectSelectionSlide(slide.id)}
                      className={`w-full text-left p-2 border rounded focus:outline-none focus:ring-2 focus:ring-red-500 ${
                        selectedSelectionSlideId === slide.id
                          ? 'bg-red-100'
                          : 'bg-white hover:bg-gray-100'
                      }`}
                      aria-pressed={selectedSelectionSlideId === slide.id}
                    >
                      {`Page ${isNaN(extractPageNumber(slide.filename)) ? 'Unknown' : extractPageNumber(slide.filename)}`}
                    </button>
                  ))}
                </div>
              ) : (
                <p>No slides selected.</p>
              )}
            </div>
          )}
        </div>

        {/* Right Column: Slide Viewer */}
        <div
          className="w-full md:w-2/3 border p-2 flex items-center justify-center bg-gray-100 relative cursor-pointer"
          onClick={() => {
            if (imageUrl) {
              console.log('Opening image modal.');
              setIsModalOpen(true);
            }
          }}
        >
          <div className="w-full h-full max-h-screen max-w-screen-lg">
            {isImageLoading ? (
              <div className="flex flex-col items-center justify-center h-full">
                <FaSpinner className="animate-spin text-blue-500" size={30} />
                <p className="mt-2">Loading image...</p>
              </div>
            ) : imageError ? (
              <p className="text-red-500">{imageError}</p>
            ) : imageUrl ? (
              <img
                src={imageUrl}
                alt={`Slide ${selectedSlideId}`}
                className="object-contain w-full h-full"
                style={{ maxHeight: '100%', maxWidth: '100%' }}
                loading="lazy"
              />
            ) : (
              <p>Select a slide to preview</p>
            )}
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <div className="mt-4 flex justify-end">
        <button
          onClick={handleSubmit}
          disabled={slideSelection.length === 0 || isSubmitting}
          className={`px-6 py-2 rounded ${
            slideSelection.length === 0 || isSubmitting
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
        >
          {isSubmitting ? 'Processing...' : 'Submit'}
        </button>
      </div>

      {/* Image Modal */}
      {isModalOpen && selectedSlideId && imageUrl && (
        <ImageModal
          imageUrl={imageUrl}
          onClose={() => {
            console.log('Closing image modal.');
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default DataExtraction;
